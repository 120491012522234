define('dashboard/mixins/community-report', ['exports', 'dashboard/objects/incentive-engine', 'lodash/array', 'lodash/lang', 'dashboard/utils/sum-array', 'dashboard/utils/sum-arrays', 'dashboard/utils/sum-many-arrays', 'dashboard/utils/conjugate-incentive-term', 'dashboard/utils/array-of-length', 'dashboard/utils/get-aggregate-engine-prop'], function (exports, _incentiveEngine, _array2, _lang2, _sumArray, _sumArrays, _sumManyArrays, _conjugateIncentiveTerm, _arrayOfLength, _getAggregateEngineProp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('incentiveTypes', ['capInvestRebatesIncentive', 'salesTaxRebatesIncentive', 'nonTaxIncentive', 'abatementsIncentive', 'hotelRebatesIncentive']);
    },


    scenarioController: Ember.inject.controller('scenario'),

    // boolean to include TIF district in participating community incentives
    participatingReportTifDistrict: Ember.computed.and('tifDistrict', 'model.usesTifDistrict', 'model.communityReportIncludesTif'),

    // @TODO: make sure this behaves nicely with the ability to add tifs to community districts since this only accounts for regular districts
    // array of districts to show in the community report district select as well as which districts to display on the community report
    selectedCommunityReportDistricts: Ember.computed('sortedDistricts.[]', 'model.communityReportDistrictIds.[]', 'model.districts.[]', 'participatingReportTifDistrict', function () {
      var _this = this;

      var selectedDistrictIds = this.get('sortedDistricts').mapBy('id'); // default to sortedDistricts

      // check for participating community incentive districts
      if (this.get('selectedCommunityDistricts.length')) {
        selectedDistrictIds = this.get('selectedCommunityDistricts').mapBy('id');
      }

      // if already selected community report districts, use those
      if (this.get('model.communityReportDistrictIds.length')) {
        selectedDistrictIds = this.get('model.communityReportDistrictIds');
      }

      // only return districts which are currently being used in this scenario
      var taxDistricts = selectedDistrictIds.map(function (districtId) {
        return _this.get('model.districts').findBy('id', districtId);
      }).sortBy('parentGroup.groupOrder');

      if (this.get('participatingReportTifDistrict')) {
        taxDistricts = taxDistricts.concat([this.get('tifDistrict')]);
      }
      return _array2.default.compact(taxDistricts);
    }),

    // array of included community report districts mapped by name
    communityReportDistrictNames: Ember.computed('selectedCommunityReportDistricts.@each.name', function () {
      if (this.get('selectedCommunityReportDistricts.length') < 5) {
        return false;
      }
      return this.get('selectedCommunityReportDistricts').mapBy('name').join(', ');
    }),

    // array of impact-engines for each district shown on the community summary report
    communityEngines: Ember.computed('selectedCommunityReportDistricts', 'engines.@each.district', 'participatingReportTifDistrict', function () {
      var _this2 = this;

      var districtEngines = this.get('selectedCommunityReportDistricts').map(function (district) {
        return _this2.get('engines').find(function (engine) {
          return engine.get('district.id') === district.get('id');
        });
      });

      if (this.get('participatingReportTifDistrict')) {
        districtEngines = districtEngines.concat([this.get('tifImpactEngine')]);
      }

      return _array2.default.compact(districtEngines);
    }),

    // object breaking community engines into first 3 and other engines
    communityCompactEngines: Ember.computed('communityEngines.[]', 'participatingReportTifDistrict', function () {
      var communityEnginesCopy = this.get('communityEngines').toArray(); // we're just rearranging, we don't want to change this for other properties
      var participatingTif = this.get('participatingReportTifDistrict');
      var districtIncludeCount = participatingTif ? 2 : 3;
      // let districtIncludeCount = 3;

      if (participatingTif) {
        communityEnginesCopy.pop();
      }

      return Ember.Object.create({
        firstThreeEngines: communityEnginesCopy.slice(0, districtIncludeCount),
        otherEngines: communityEnginesCopy.slice(districtIncludeCount, communityEnginesCopy.get('length'))
      });
    }),

    // the engine with the largest percent of multiplier region is really just the engine with the most total jobs
    // this could be a current, expansion, or total engine depending on the engines property in scenario controller
    engineWithLargestPercentOfRegion: Ember.computed('communityEngines.@each.eiJobsLastTotalCumulative', function () {
      return this.get('communityEngines').filter(function (e) {
        return e.get('group.label') !== 'TIF';
      }) // exclude TIFs from this list
      .sortBy('eiJobsLastTotalCumulative').get('lastObject');
    }),

    // find currentEngine version of above engine
    currentEngineWithLargestPercentOfRegion: Ember.computed('engineWithLargestPercentOfRegion', 'currentEngines.@each.district', function () {
      return this.get('currentEngines').findBy('district.id', this.get('engineWithLargestPercentOfRegion.district.id'));
    }),

    // find totalEngine version of above engine
    expansionEngineWithLargestPercentOfRegion: Ember.computed('engineWithLargestPercentOfRegion', 'expansionEngines.@each.district', function () {
      return this.get('expansionEngines').findBy('district.id', this.get('engineWithLargestPercentOfRegion.district.id'));
    }),

    aggregateAnnualNetBenefitsLessIncentivesGraphData: Ember.computed('communityEngines.@each.annualNetBenefitsLessIncentivesGraphData', 'showCommunityIncentive', 'communityIncentive.incentives', function () {
      var allEnginesGraphData = this.get('communityEngines').mapBy('annualNetBenefitsLessIncentivesGraphData'),
          templateData = allEnginesGraphData.get('firstObject') || [],
          aggregateGraphData = [];

      // graph data looks like [{year: 1, type: "benefit", origValue: 1234.12, value: 1234.12}, {year: 2, ...}, ...]
      aggregateGraphData = templateData.map(function (data, i) {
        var newData = Ember.Object.create(data);
        var origValue = allEnginesGraphData.reduce(function (total, engineGraphData) {
          var conditionalOrigValue = engineGraphData[i] && engineGraphData[i].origValue ? engineGraphData[i].origValue : 0;
          return total + conditionalOrigValue;
        }, 0);
        newData.set('origValue', origValue);

        var value = allEnginesGraphData.reduce(function (total, engineGraphData) {
          return total + (engineGraphData[i] ? engineGraphData[i].value : 0);
        }, 0);
        newData.set('value', value);

        return newData;
      });

      // if zero allocation community incentive, make sure the community incentive is still shown on the graph,
      // even though it won't be reflected in district graph data
      if (this.get('showCommunityIncentive')) {
        var communityIncentive = this.get('communityIncentive.incentives');
        communityIncentive.forEach(function (incentive, i) {
          var foundYear = aggregateGraphData.find(function (data) {
            return data.year === i + 1 && data.type === "incentive";
          });

          if (foundYear) {
            foundYear.value = foundYear.value - incentive;
            foundYear.origValue = foundYear.origValue - incentive;
          }
        });
      }

      return aggregateGraphData;
    }),

    aggregateEnginesTotalPublicSupport: Ember.computed('communityEngines.@each.totalPublicSupport', function () {
      return !!(_array2.default.compact(this.get('communityEngines').mapBy('totalPublicSupport')).get('length') || this.get('showCommunityIncentive'));
    }),

    aggregateEnginesHasIncentives: Ember.computed('communityEngines.@each.hasIncentives', function () {
      return !!(_array2.default.compact(this.get('communityEngines').mapBy('hasIncentives')).get('length') || this.get('showCommunityIncentive'));
    }),

    aggregateEnginesHasTifContributions: Ember.computed('communityEngines.@each.engineHasTifContributions', function () {
      return !!_array2.default.compact(this.get('communityEngines').mapBy('engineHasTifContributions')).get('length');
    }),

    // aggregates totals of the given props across "other engines"
    // returns an ember object with properties mimicking single engine props
    aggregatedOtherEngines: Ember.computed('communityCompactEngines.otherEngines.@each.{totalBenefitsTotal,totalCostsTotal,netBenefitsTotal,totalIncentive,totalIncentivesTotal,netBenefitsLessIncentivesTotal,netPresentValueNetBenefitsTotal,netPresentValueNetBenefitsLessIncentivesTotal}', function () {

      var otherEngines = this.get('communityCompactEngines.otherEngines');
      var props = ['totalBenefitsTotal', 'totalCostsTotal', 'netBenefitsTotal', 'totalIncentive', 'totalIncentivesTotal', 'netBenefitsLessIncentivesTotal', 'netPresentValueNetBenefitsTotal', 'netPresentValueNetBenefitsLessIncentivesTotal', 'tifContributionTotal'];

      var arrayProps = ['netBenefitsLessIncentivesAnnual'];

      if (!otherEngines.get('length')) {
        return;
      }

      var output = Ember.Object.create({
        district: {
          name: otherEngines.get('length') > 1 ? "Other Districts" : otherEngines.get('firstObject.district.name')
        }
      });

      props.forEach(function (prop) {
        output[prop] = (0, _getAggregateEngineProp.getAggregateProperty)(otherEngines, prop);
      });

      arrayProps.forEach(function (prop) {
        output[prop] = (0, _getAggregateEngineProp.getAggregateArrayProperty)(otherEngines, prop);
      });

      return output;
    }),

    // array of 3 engines + aggregated other engine with properties specified above
    aggregateEngines: Ember.computed('communityEngines', 'aggregatedOtherEngines', 'participatingReportTifDistrict', function () {
      var firstThreeEngines = this.get('communityCompactEngines.firstThreeEngines'),
          aggregatedOtherEngines = this.get('aggregatedOtherEngines'),
          tifImpactEngine = this.get('tifImpactEngine');
      // let aggregateEngines = [];

      // if (!aggregatedOtherEngines) {
      //   aggregateEngines = firstThreeEngines;
      // }

      var aggregateEngines = firstThreeEngines.concat([aggregatedOtherEngines]);

      if (this.get('participatingReportTifDistrict')) {
        aggregateEngines = aggregateEngines.concat([tifImpactEngine]);
      }

      return _array2.default.compact(aggregateEngines);
    }),

    // array of community engines mapped by Incentive Engine objects
    aggregateIncentiveEngines: Ember.computed('communityCompactEngines.{firstThreeEngines.@each.totalIncentivesTotal,otherEngines.@each.totalIncentivesTotal}', 'tifImpactEngine.totalIncentivesTotal', function () {

      var incentiveEngines = this.get('communityCompactEngines.firstThreeEngines').mapBy('incentiveEngine');
      if (this.get('aggregatedOtherEngines')) {
        incentiveEngines = incentiveEngines.concat(this.get('aggregateOtherDistrictsCommunityIncentive'));
      }
      if (this.get('participatingReportTifDistrict')) {
        incentiveEngines.push(this.get('tifIncentives'));
      }
      return incentiveEngines;
    }),

    // array of standard benefits aggregated across all "other districts"
    otherEngineStandardBenefits: Ember.computed('communityEngines.@each.communityReportRevenues', function () {
      var communityReportRevenues = this.get('communityEngines.firstObject.communityReportRevenues') || [],
          otherEngines = this.get('communityCompactEngines.otherEngines');

      // for each community revenue, return a sum of that revenue across all other engines
      return communityReportRevenues.map(function (revenue, i) {
        var revenueTotal = otherEngines.reduce(function (total, engine) {
          return total + engine.get('communityReportRevenues')[i].total;
        }, 0);
        var modifiedArray = otherEngines.map(function (engine) {
          return engine.get('communityReportRevenues')[i].modified;
        });
        var modified = _array2.default.without(modifiedArray, undefined, null, '', false);
        return {
          total: revenueTotal,
          modified: modified.length ? '*' : ''
        };
      });
    }),

    // array of first 5 "standard" benefits for first 3 districts + aggregated "other districts"
    communitySummaryStandardBenefits: Ember.computed('communityEngines.@each.communityReportRevenues', 'participatingReportTifDistrict', function () {
      var _this3 = this;

      var engines = this.get('communityEngines'),
          firstThreeEngines = this.get('communityCompactEngines.firstThreeEngines'),
          otherEngines = this.get('communityCompactEngines.otherEngines'),
          otherEngineStandardBenefits = this.get('otherEngineStandardBenefits'),
          communityReportRevenues = engines.get('firstObject.communityReportRevenues') || [],
          tifImpactEngine = this.get('tifImpactEngine');

      return communityReportRevenues.map(function (revenue, i) {
        var grandTotal = 0;
        var engineRevenues = firstThreeEngines.map(function (engine) {
          var total = engine.get('communityReportRevenues')[i].total || 0;
          var modified = engine.get('communityReportRevenues')[i].modified || '';
          grandTotal += total;
          return { total: total, modified: modified };
        });

        var otherBenefits = otherEngineStandardBenefits[i] || { total: 0, modified: '' };
        if (otherEngines.get('length')) {
          engineRevenues.push(otherBenefits);
        }
        grandTotal += otherBenefits.total;

        if (_this3.get('participatingReportTifDistrict')) {
          var tifBenefit = { total: tifImpactEngine.get('communityReportRevenues')[i].total } || { total: 0, modified: '' };
          engineRevenues.push(tifBenefit);
          grandTotal += tifBenefit.total;
        }

        return {
          label: revenue ? revenue.label : '',
          engineRevenues: engineRevenues,
          grandTotal: grandTotal
        };
      });
    }),

    // a single report row of all other benefits for first 3 districts + aggregated "other districts"
    communitySummaryOtherBenefits: Ember.computed('engines.@each.communityOtherRevenues', 'communityCompactEngines.{firstThreeEngines,otherEngines}', function () {
      var firstThreeEngines = this.get('communityCompactEngines.firstThreeEngines'),
          otherEngines = this.get('communityCompactEngines.otherEngines');

      var grandTotal = 0;
      var engineRevenues = firstThreeEngines.map(function (engine) {
        var total = engine.get('communityOtherRevenues').reduce(function (sum, revenue) {
          return sum + revenue.total;
        }, 0);
        grandTotal += total;
        return { total: total };
      });
      // add together all other benefits for each other district
      var otherDistrictOtherBenefits = otherEngines.map(function (engine) {
        return engine.get('communityOtherRevenues').reduce(function (sum, revenue) {
          return sum + revenue.total;
        }, 0);
      });
      otherDistrictOtherBenefits = (0, _sumArray.default)(otherDistrictOtherBenefits) || 0;
      if (otherEngines.get('length')) {
        engineRevenues.push({ total: otherDistrictOtherBenefits });
      }
      grandTotal += otherDistrictOtherBenefits;

      if (this.get('participatingReportTifDistrict')) {
        engineRevenues.push({ total: 0 });
      }

      return {
        label: "Other Benefits",
        engineRevenues: engineRevenues, // 3 districts + other + tif
        grandTotal: grandTotal
      };
    }),

    // concatenated array of community standard and other benefits to loop through on template
    communitySummaryBenefits: Ember.computed('communitySummaryStandardBenefits.@each.{label,engineRevenues,total}', 'communitySummaryOtherBenefits.{engineRevenues,total}', function () {
      return this.get('communitySummaryStandardBenefits').concat(this.get('communitySummaryOtherBenefits'));
    }),

    // single standard cost aggregated across all "other districts"
    otherEngineStandardCost: Ember.computed('communityEngines.@each.communityReportCost', function () {
      var otherEngines = this.get('communityCompactEngines.otherEngines');

      // return a sum of the standard community cost across all other engines
      return otherEngines.reduce(function (total, engine) {
        return total + engine.get('communityReportCost').total;
      }, 0);
    }),

    // object representing single "standard" cost for first 3 districts + aggregated "other districts"
    communitySummaryStandardCost: Ember.computed('communityEngines.@each.communityReportCost', 'participatingReportTifDistrict', function () {
      var firstThreeEngines = this.get('communityCompactEngines.firstThreeEngines'),
          otherEngines = this.get('communityCompactEngines.otherEngines'),
          otherEngineStandardCost = this.get('otherEngineStandardCost'),
          communityReportCost = this.get('communityEngines.firstObject.communityReportCost');

      var total = 0;
      var engineCosts = firstThreeEngines.map(function (engine) {
        var cost = engine.get('communityReportCost').total || 0;
        total += cost;
        return cost;
      });

      var otherCost = otherEngineStandardCost || 0;
      if (otherEngines.get('length')) {
        engineCosts.push(otherCost);
      }
      total += otherCost;

      // no costs associated with tif district
      if (this.get('participatingReportTifDistrict')) {
        engineCosts.push(0);
      }

      return {
        label: communityReportCost ? communityReportCost.label : '',
        engineCosts: engineCosts,
        total: total
      };
    }),

    // a single report row of all other costs for first 3 districts + aggregated "other districts"
    communitySummaryOtherCosts: Ember.computed('communityEngines.@each.communityOtherCosts', function () {
      var firstThreeEngines = this.get('communityCompactEngines.firstThreeEngines'),
          otherEngines = this.get('communityCompactEngines.otherEngines');

      var total = 0;
      var engineCosts = firstThreeEngines.map(function (engine) {
        var engineTotal = engine.get('communityOtherCosts').reduce(function (sum, revenue) {
          return sum + revenue.total;
        }, 0);
        total += engineTotal;
        return engineTotal;
      });

      // add together all other costs for each other district
      var otherDistrictOtherCosts = otherEngines.map(function (engine) {
        return engine.get('communityOtherCosts').reduce(function (sum, revenue) {
          return sum + revenue.total;
        }, 0);
      });
      otherDistrictOtherCosts = (0, _sumArray.default)(otherDistrictOtherCosts) || 0;
      if (otherEngines.get('length')) {
        engineCosts.push(otherDistrictOtherCosts);
      }
      total += otherDistrictOtherCosts;

      if (this.get('participatingReportTifDistrict')) {
        engineCosts.push(0);
      }

      return {
        label: "Other Costs",
        engineCosts: engineCosts, // 3 districts + other
        total: total
      };
    }),

    // concatenated array of single standard community cost and aggregated "other costs"
    communitySummaryCosts: Ember.computed('communitySummaryStandardCost.{label,engineCosts,total}', 'communitySummaryOtherCosts.{label,engineCosts,total}', function () {
      return [this.get('communitySummaryStandardCost')].concat(this.get('communitySummaryOtherCosts'));
    }),

    // boolean if there are any abatements, rebates, or TIFs across all community engines
    communitySummaryExcludesValues: Ember.computed('model.assumption.abatementsAsCosts', 'aggregateEngines.@each.engineHasPublicSupport', function () {
      var anyPublicSupport = !!_array2.default.compact(this.get('communityEngines').mapBy('engineHasPublicSupport')).get('length');
      return !this.get('model.assumption.abatementsAsCosts') && anyPublicSupport;
    }),

    // array of impact-engine incentiveCosts mapped across first 3 + other community engines
    aggregateIncentiveCosts: Ember.computed('communityEngines.@each.incentiveCosts', 'participatingReportTifDistrict', function () {
      var firstThreeEngines = this.get('communityCompactEngines.firstThreeEngines'),
          otherEngines = this.get('communityCompactEngines.otherEngines'),
          tifImpactEngine = this.get('tifImpactEngine'),
          incentiveTypes = this.get('incentiveTypes');

      var aggregateIncentiveCosts = [];

      // first three engines
      aggregateIncentiveCosts = firstThreeEngines.mapBy('incentiveCosts');

      // other engines
      var otherEnginesIncentiveCosts = Ember.Object.create();
      // incentiveCosts look like: [nonTaxIncentive: 0, abatementsIncentive: 0, capInvestRebatesIncentive: 0, salesTaxRebatesIncentive: 0];
      incentiveTypes.forEach(function (incentiveType) {
        otherEnginesIncentiveCosts.set(incentiveType, otherEngines.reduce(function (total, engine) {
          return total + (engine.get('incentiveCosts').get(incentiveType) || 0);
        }, 0));
      });
      if (otherEngines.get('length')) {
        aggregateIncentiveCosts.push(otherEnginesIncentiveCosts);
      }

      // TIF District
      if (this.get('participatingReportTifDistrict')) {
        aggregateIncentiveCosts.push(tifImpactEngine.get('incentiveCosts'));
      }

      return aggregateIncentiveCosts;
    }),

    // object modeled after impact-engine incentiveCosts representing totals across each incentive type
    aggregateIncentiveCostsTotals: Ember.computed('communityEngines.@each.incentiveCosts', function () {
      var engines = this.get('communityEngines');
      var incentiveTypes = this.get('incentiveTypes');
      var totals = Ember.Object.create();

      incentiveTypes.forEach(function (incentiveType) {
        totals.set(incentiveType, engines.reduce(function (total, engine) {
          return total + (engine.get('incentiveCosts').get(incentiveType) || 0);
        }, 0));
      });

      return totals;
    }),

    aggregateAnnualCommunityNetBenefits: Ember.computed('communityEngines.@each.annualNetBenefitsDirect', function () {
      if (!this.get('communityEngines.length')) {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      var netBenefitsArrays = this.get('communityEngines').mapBy('annualNetBenefitsDirect');
      return (0, _sumManyArrays.default)(netBenefitsArrays);
    }),

    // equivalent to the communityIncentive property in the community-incentives mixin, but this includes all incentives for
    // included districts on the community report
    aggregatedCommunityIncentive: Ember.computed('model.{assumption,communityIncentiveSchedule}', 'aggregateAnnualCommunityNetBenefits', 'primaryEngine.eiJobs.lastDirectCumulative', 'communityEngines.@each.totalIncentivesTotal', function () {

      var communityNetBenefits = this.get('aggregateAnnualCommunityNetBenefits'),
          directJobs = this.get('primaryEngine.eiJobs.lastDirectCumulative');

      var incentive = (0, _sumManyArrays.default)(this.get('communityEngines').mapBy('incentiveEngine.incentives'));

      // if zero allocation, we need to include the community incentive since it won't be accounted for in individual districts
      if (this.get('showCommunityIncentive')) {
        incentive = (0, _sumArrays.default)(incentive, this.get('communityIncentive.incentives'));
      }

      var schedule = Ember.Object.create({ incentives: incentive });

      return _incentiveEngine.default.create({
        assumption: this.get('model.assumption'),
        schedule: schedule,
        jobs: directJobs,
        annualNetBenefits: communityNetBenefits,
        incentiveComparison: 'nonTax',
        scenario: this
      });
    }),

    // array of annual direct net benefits for all "other districts"
    otherEnginesAggregateAnnualNetBenefits: Ember.computed('communityCompactEngines.otherEngines.@each.annualNetBenefitsDirect', function () {
      if (!this.get('communityCompactEngines.otherEngines.length')) {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      var netBenefitsArrays = this.get('communityCompactEngines.otherEngines').mapBy('annualNetBenefitsDirect');
      return (0, _sumManyArrays.default)(netBenefitsArrays);
    }),

    // Incentive Engine using combined net benefits for all "other districts"
    aggregateOtherDistrictsCommunityIncentive: Ember.computed('model.assumption', 'otherEnginesAggregateAnnualNetBenefits', 'primaryEngine.eiJobs.lastDirectCumulative', 'communityCompactEngines.otherEngines.@each.totalIncentivesTotal', function () {
      var otherEnginesNetBenefits = this.get('otherEnginesAggregateAnnualNetBenefits'),
          directJobs = this.get('primaryEngine.eiJobs.lastDirectCumulative');

      var incentive = (0, _sumManyArrays.default)(this.get('communityCompactEngines.otherEngines').mapBy('incentiveEngine.incentives'));
      var schedule = Ember.Object.create({ incentives: incentive });

      return _incentiveEngine.default.create({
        assumption: this.get('model.assumption'),
        schedule: schedule,
        jobs: directJobs,
        annualNetBenefits: otherEnginesNetBenefits,
        incentiveComparison: 'nonTax',
        scenario: this
      });
    }),

    communityTifTotals: Ember.computed('communityEngines.@each.{tifContributionTotal,netPresentValueTifContributionsTotal}', 'tifIncentives.totalIncentive', 'communityEngines', function () {

      var engines = this.get('communityEngines'),

      // tifIncentives = this.get('tifIncentives'),
      totalBenefitsTotal = void 0;
      // totalIncentivesTotal,
      // netBenefitsLessIncentivesTotal,
      // netPresentValueNetBenefitsTotal,
      // netPresentValueNetBenefitsLessIncentivesTotal;

      totalBenefitsTotal = engines.reduce(function (total, engine) {
        return total + engine.get('tifContributionTotal');
      }, 0);

      // negative number
      // messes up communityTotals if this is included in total incentives
      // totalIncentivesTotal = tifIncentives ? tifIncentives.get('totalIncentiveSummaryReport') : 0;

      // netBenefitsLessIncentivesTotal = totalBenefitsTotal + totalIncentivesTotal;

      // netPresentValueNetBenefitsTotal = engines.reduce((total, engine) => {
      //   return total + engine.get('netPresentValueTifContributionsTotal');
      // }, 0);

      // netPresentValueNetBenefitsLessIncentivesTotal = this.get('netPresentValueTifContributionsLessIncentives.total') || 0;

      return Ember.Object.create({
        totalBenefitsTotal: totalBenefitsTotal,
        totalCostsTotal: 0,
        netBenefitsTotal: 0,
        totalIncentivesTotal: 0,
        netBenefitsLessIncentivesTotal: 0,
        netPresentValueNetBenefitsTotal: 0,
        netPresentValueNetBenefitsLessIncentivesTotal: 0
      });
    }),

    communityTotals: Ember.computed('communityEngines.@each.{totalBenefitsTotal,totalCostsTotal,netBenefitsTotal,totalIncentivesTotal,netBenefitsLessIncentivesTotal,netPresentValueNetBenefitsTotal,netPresentValueNetBenefitsLessIncentivesTotal}', 'communityTifTotals.@each.{totalBenefitsTotal,totalCostsTotal,netBenefitsTotal,totalIncentivesTotal,netBenefitsLessIncentivesTotal,netPresentValueNetBenefitsTotal,netPresentValueNetBenefitsLessIncentivesTotal}', function () {
      var engines = this.get('communityEngines');

      var totals = Ember.Object.create(),
          props = ['totalBenefitsTotal', 'totalCostsTotal', 'totalIncentivesTotal', // negative number
      'totalIncentive', // positive number (same as above)
      'netBenefitsTotal', 'netBenefitsLessIncentivesTotal', 'netPresentValueNetBenefitsTotal', 'netPresentValueNetBenefitsLessIncentivesTotal'];

      props.forEach(function (prop) {
        var total = (0, _getAggregateEngineProp.getAggregateProperty)(engines, prop);
        totals.set(prop, total);
      });

      return totals;
    }),

    communityPublicSupportTotals: Ember.computed('communityEngines.@each.{totalPropertyTaxAbated,allSalesTaxRebatesTotal,nonTaxIncentiveTotal}', 'tifIncentives.totalIncentive', 'model.usesTifDistrict', function () {
      var engines = this.get('communityEngines'),
          abatements = (0, _sumArray.default)(engines.mapBy('totalPropertyTaxAbated')),
          rebates = (0, _sumArray.default)(engines.mapBy('allSalesTaxRebatesTotal')),
          incentives = (0, _sumArray.default)(engines.mapBy('nonTaxIncentiveTotal')),
          tifIncentives = this.get('tifIncentives.totalIncentive');

      if (tifIncentives && this.get('model.usesTifDistrict')) {
        incentives += tifIncentives;
      }

      return Ember.Object.create({
        abatements: abatements,
        rebates: rebates,
        incentives: incentives,
        total: abatements + rebates + incentives
      });
    }),

    communityIncentivesSubtotal: Ember.computed('communityIncentive.totalIncentiveSummaryReport', 'communityTotals.totalIncentivesTotal', 'showCommunityIncentive', function () {
      var communityIncentive = this.get('communityIncentive.totalIncentiveSummaryReport'),
          incentiveSubtotal = this.get('communityTotals.totalIncentivesTotal'),
          showCommunityIncentive = this.get('showCommunityIncentive');

      // if community districts match, and there is a community incentive with no attribution to districts,
      // then include community incentive along with other incentives on community report
      if (showCommunityIncentive && communityIncentive) {
        return communityIncentive + incentiveSubtotal;
      }

      return incentiveSubtotal;
    }),

    // boolean saying whether community incentive districts are the same as the districts selected for the community report
    communityDistrictsMatch: Ember.computed('selectedCommunityReportDistricts', 'selectedCommunityDistricts', function () {
      var communityIncentiveDistricts = this.get('selectedCommunityDistricts') || [],
          communityReportDistricts = this.get('selectedCommunityReportDistricts') || [],
          incentiveDistricts = communityIncentiveDistricts.mapBy('id'),
          reportDistricts = communityReportDistricts.mapBy('id');

      return _array2.default.difference(incentiveDistricts, reportDistricts).length === 0;
    }),

    showCommunityIncentive: Ember.computed.and('communityDistrictsMatch', 'noAllocationCommunityIncentive'),

    communityDistrictsMatchExactly: Ember.computed('selectedCommunityReportDistricts', 'selectedCommunityDistricts', function () {
      var communityIncentiveDistricts = this.get('selectedCommunityDistricts') || [],
          communityReportDistricts = this.get('selectedCommunityReportDistricts') || [],
          incentiveDistricts = communityIncentiveDistricts.mapBy('id'),
          reportDistricts = communityReportDistricts.mapBy('id');

      return _lang2.default.isEqual(incentiveDistricts, reportDistricts);
    }),

    communityNetBenefitsLessIncentivesTotal: Ember.computed('communityTotals.netBenefitsLessIncentivesTotal', function () {
      var communityNetLessIncentives = this.get('communityTotals.netBenefitsLessIncentivesTotal'),
          showCommunityIncentive = this.get('showCommunityIncentive'),
          communityIncentiveTotal = this.get('communityIncentive.totalIncentive');

      if (showCommunityIncentive && communityIncentiveTotal) {
        return communityNetLessIncentives - communityIncentiveTotal;
      }

      return communityNetLessIncentives;
    }),

    // calculate the net present value if zero allocation and community incentive
    communityNetPresentValueNetBenefitsLessIncentives: Ember.computed('aggregateEngines.@each.netBenefitsLessIncentivesTotal', 'showCommunityIncentive', 'communityIncentive.{incentives,totalIncentive}', 'model,assumption.discountForCalculatingPresentValueCostsBenefits', function () {
      var _this4 = this;

      var annualNetBenefits = this.get('aggregateEngines.firstObject.netBenefitsLessIncentivesAnnual'),
          discountRate = parseFloat(this.get('model.assumption.discountForCalculatingPresentValueCostsBenefits')),
          annual = void 0,
          total = void 0;

      var aggregateAnnualNetBenefits = annualNetBenefits.map(function (benefit, i) {
        return _this4.get('aggregateEngines').reduce(function (total, engine) {
          return total + engine.get('netBenefitsLessIncentivesAnnual')[i];
        }, 0);
      });

      // if no allocation community incentive and matching community report/incentive districts,
      // reduce annual net benefits by incentive each year and recalculate net present value
      if (this.get('showCommunityIncentive') && this.get('communityIncentive.totalIncentive')) {
        var communityIncentiveAnnual = this.get('communityIncentive.incentives');
        aggregateAnnualNetBenefits = aggregateAnnualNetBenefits.map(function (benefit, i) {
          return benefit - (communityIncentiveAnnual[i] || 0);
        });
      }

      annual = aggregateAnnualNetBenefits.map(function (benefit, i) {
        return benefit / Math.pow(1 + discountRate, i + 1);
      });

      total = (0, _sumArray.default)(annual);

      return Ember.Object.create({ annual: annual, total: total });
    }),

    communityMaterialsEquipmentIncentivePhrases: Ember.computed('communityEngines.firstObject.schedule.materialsEquipmentTaxIncentiveTerm', 'model.assumption.materialsEquipmentTaxIncentiveTerm', function () {
      var districtTerm = this.get('communityEngines.firstObject.schedule.materialsEquipmentTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('model.assumption.materialsEquipmentTaxIncentiveTerm');

      return (0, _conjugateIncentiveTerm.default)(districtTerm ? districtTerm : defaultAssumptionTerm);
    }),

    communitySalesTaxIncentivePhrases: Ember.computed('communityEngines.firstObject.schedule.salesTaxIncentiveTerm', 'model.assumption.salesTaxIncentiveTerm', function () {
      var districtTerm = this.get('communityEngines.firstObject.schedule.salesTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('model.assumption.salesTaxIncentiveTerm');

      return (0, _conjugateIncentiveTerm.default)(districtTerm ? districtTerm : defaultAssumptionTerm);
    }),

    communityPropertyTaxIncentivePhrases: Ember.computed('communityEngines.firstObject.schedule.propertyTaxIncentiveTerm', 'model.assumption.propertyTaxIncentiveTerm', function () {
      var districtTerm = this.get('communityEngines.firstObject.schedule.propertyTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('model.assumption.propertyTaxIncentiveTerm');

      return (0, _conjugateIncentiveTerm.default)(districtTerm ? districtTerm : defaultAssumptionTerm);
    }),

    communityHotelTaxIncentivePhrases: Ember.computed('communityEngines.firstObject.schedule.hotelTaxIncentiveTerm', 'model.assumption.hotelTaxIncentiveTerm', function () {
      var districtTerm = this.get('communityEngines.firstObject.schedule.hotelTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('model.assumption.hotelTaxIncentiveTerm');

      return (0, _conjugateIncentiveTerm.default)(districtTerm ? districtTerm : defaultAssumptionTerm);
    }),

    actions: {
      selectCommunityReportDistricts: function selectCommunityReportDistricts(selectedDistricts) {
        var _this5 = this;

        var taxDistricts = selectedDistricts.filter(function (district) {
          return _this5.get('model.districts').includes(district);
        });
        this.set('model.communityReportDistrictIds', taxDistricts.mapBy('id'));

        var tifSelected = selectedDistricts.includes(this.get('tifDistrict'));
        this.set('model.communityReportIncludesTif', !!tifSelected);

        this.get('scenarioController').send('save');
      },
      setModalVisited: function setModalVisited() {
        if (!this.get('model.visitedCommunityReport')) {
          this.set('model.visitedCommunityReport', true);
          this.get('scenarioController').send('save');
        }
      }
    }
  });
});