define('dashboard/controllers/scenario', ['exports', 'dashboard/objects/column', 'dashboard/objects/impact-engine', 'dashboard/objects/tif-impact-engine', 'dashboard/objects/tif-incentive-engine', 'dashboard/objects/impact-engine-current', 'dashboard/objects/impact-engine-total', 'lodash/lang', 'lodash/string', 'dashboard/utils/sum-array', 'dashboard/utils/sum-many-arrays', 'dashboard/utils/float-map', 'dashboard/utils/add-each', 'dashboard/utils/array-of-length', 'dashboard/utils/stringify', 'dashboard/utils/make-inputs', 'dashboard/utils/make-increase', 'dashboard/helpers/scenario-title', 'dashboard/utils/cumulative-sum', 'dashboard/utils/aggregate-props', 'dashboard/utils/scenario-inputs/index', 'dashboard/fixtures/states', 'dashboard/mixins/scenario-collapsible-sections', 'dashboard/mixins/color-scheme', 'dashboard/mixins/reusable-scenario-properties', 'dashboard/mixins/community-incentives', 'dashboard/mixins/essentials-actions', 'dashboard/mixins/state-taxes'], function (exports, _column, _impactEngine, _tifImpactEngine, _tifIncentiveEngine, _impactEngineCurrent, _impactEngineTotal, _lang2, _string2, _sumArray, _sumManyArrays, _floatMap, _addEach, _arrayOfLength, _stringify, _makeInputs, _makeIncrease, _scenarioTitle, _cumulativeSum, _aggregateProps, _index, _states, _scenarioCollapsibleSections, _colorScheme, _reusableScenarioProperties, _communityIncentives, _essentialsActions, _stateTaxes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_scenarioCollapsibleSections.default, _colorScheme.default, _reusableScenarioProperties.default, _communityIncentives.default, _essentialsActions.default, _stateTaxes.default, {

    notify: Ember.inject.service(),
    adapterErrorNotifier: Ember.inject.service(),

    queryParams: ['loc'],
    loc: null,

    sections: _index.default,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('directSorting', ['type:desc']);
    },


    scenarioRequestFormConfirmHidden: true,
    scenarioSubtitleModalHidden: true,
    scenarioDescriptionModalHidden: true,
    scenarioLocationModalHidden: true,
    scenarioIndustryModalHidden: true,
    salesTaxIncentiveTermModalHidden: true,

    primaryDistrictDefined: false,
    demoDataLoaded: true,
    demoDataUnloaded: Ember.computed.not('demoDataLoaded'),

    scenarioMultiplier: null,

    cloningDistrict: false,

    scenarioRequestingUpgrade: null,

    states: _states.default,

    hotelQuestion: Ember.computed.alias('sections.future.hotelNightsVisitors.content.firstObject'),

    idbLogoUrl: Ember.computed('client.colorTheme', function () {
      var colorTheme = this.get('client.colorTheme');
      var url = '/assets/images/logo.png'; // default logo

      if (colorTheme === 'Red' || colorTheme === 'Green') {
        url = '/assets/images/logo_grey_new.png';
      }
      return url;
    }),

    primaryAppliesLand: Ember.computed.alias('primaryDistrict.doesApplyPropertyTaxRateLand'),
    primaryAppliesBuildings: Ember.computed.alias('primaryDistrict.doesApplyPropertyTaxRateBuildings'),
    primaryAppliesFFE: Ember.computed.alias('primaryDistrict.doesApplyPropertyTaxRateFFE'),
    primaryAppliesInventories: Ember.computed.alias('primaryDistrict.doesApplyPropertyTaxRateInventories'),
    primaryAppliesAnyCapitalInvestment: Ember.computed.or('primaryAppliesLand', 'primaryAppliesBuildings', 'primaryAppliesFFE', 'primaryAppliesInventories'),

    layout: Ember.inject.controller(),
    currentUser: Ember.computed.alias('layout.model'),

    years: Ember.computed('model.assumption.scenarioLength', function () {
      var scenarioLength = this.get('model.assumption.scenarioLength');
      return scenarioLength || '10';
    }),

    isScenarioLengthLessThanSix: Ember.computed.lt('years', 6), // hide expandable button for inputs on scenario info page

    isScenarioLengthLessThanEleven: Ember.computed.lt('years', 11), //hide expandable button for inputs on public support

    scenarioSubtitle: Ember.computed('model.subtitle', 'project.indexedScenariosOnly', function () {
      var indexedScenarios = this.get('project.indexedScenariosOnly'),
          currentIndexedScenario = indexedScenarios.findBy('id', this.get('model.id'));

      return this.get('model.subtitle') || (0, _scenarioTitle.scenarioTitle)([currentIndexedScenario]);
    }),

    districtGroups: Ember.computed('client.districtGroups', function () {
      var groups = this.get('client.districtGroups') || [];
      return groups.sortBy('isPrimary');
    }),

    primaryGroup: Ember.computed('districtGroups', function () {
      var districtGroups = this.get('districtGroups');
      if (districtGroups) {
        return districtGroups.findBy('isPrimary');
      }
    }),

    primaryDistrict: Ember.computed('sortedDistricts', 'sortedDistricts.[]', 'sortedDistricts.@each.id', function () {
      return this.get('sortedDistricts.firstObject');
    }),

    primaryGroupName: Ember.computed.reads('primaryGroup.label'),

    sortedDistrictGroups: Ember.computed('districtGroups', 'districtGroups.@each.groupOrder', function () {
      var groups = this.get('districtGroups') || [];
      return groups.sortBy('groupOrder');
    }),

    anySelectableDistricts: Ember.computed('districtGroups.@each.hasDistricts', function () {
      return this.get('districtGroups').any(function (group) {
        return group.get('hasDistricts');
      });
    }),

    sortedDistricts: Ember.computed('model.districts.[]', 'sortedDistrictGroups.@each.{districts,previousDistricts}', function () {
      var districtGroups = this.get('sortedDistrictGroups'),
          districts = this.get('model.districts') || [],
          foundDistrict = void 0;

      districtGroups.forEach(function (group) {
        var groupDistricts = group.get('districts') || [],
            groupPrevious = group.get('previousDistricts') || [];

        // Group Districts
        foundDistrict = districts.find(function (district) {
          if (!district) {
            return false;
          }
          return groupDistricts.find(function (groupDistrict) {
            return groupDistrict ? groupDistrict.get('id') === district.get('id') : false;
          });
        });

        // Previous Districts
        if (!foundDistrict) {
          foundDistrict = districts.find(function (district) {
            if (!district) {
              return false;
            }
            return groupPrevious.find(function (groupDistrict) {
              return groupDistrict ? groupDistrict.get('id') === district.get('id') : false;
            });
          });
        }

        // if still no found district here, this scenario is probably transitioned or using modified districts
        // in which case the district itself should have a reference to the districtGroup
        if (!foundDistrict) {
          foundDistrict = districts.find(function (district) {
            return district.get('districtGroups').find(function (dGroup) {
              return dGroup.get('id') === group.get('id');
            });
          });
        }

        if (foundDistrict) {
          foundDistrict.set('parentGroup', group);
          foundDistrict.set('districtOrder', group.get('groupOrder'));
        }
      });

      return districts.sortBy('districtOrder');
    }),

    // selectively toggle between current or expansion engines depending on scenario property selection
    engines: Ember.computed('model.currentVsExpansion', 'expansionEngines', 'currentEngines', 'sortedDistricts.@each', 'model.assumption', 'multipliers', 'incentiveSchedules.[]', function () {
      var currentVsExpansion = this.get('model.currentVsExpansion') || 'expansion';
      return this.get(currentVsExpansion + 'Engines');
    }),

    // previously "engines"
    expansionEngines: Ember.computed('sortedDistricts.@each', 'model.assumption', 'primaryDistrict', 'multipliers', 'incentiveSchedules.[]', function () {
      var _this = this;

      var sortedDistricts = this.get('sortedDistricts'),
          assumption = this.get('model.assumption'),
          primaryDistrict = this.get('primaryDistrict'),
          multipliers = this.get('multipliers'),
          scenario = this.get('model');

      return sortedDistricts.map(function (district) {
        var isPrimary = primaryDistrict ? district.get('id') === primaryDistrict.get('id') : false,
            engineGroup = district.get('parentGroup');

        return _impactEngine.default.create({
          isPrimary: isPrimary,
          district: district,
          schedule: scenario.getScheduleForDistrict(engineGroup),
          assumption: assumption,
          scenario: _this,
          className: 'index-' + (district ? district.get('id') : ''),
          group: engineGroup,
          multipliers: multipliers
        });
      });
    }),

    // array of current-impact-engines for each district, representing only the current operations data for a scenario
    currentEngines: Ember.computed('sortedDistricts.@each', 'model.assumption', 'primaryDistrict', 'multipliers', 'incentiveSchedules.[]', function () {
      var _this2 = this;

      var sortedDistricts = this.get('sortedDistricts'),
          assumption = this.get('model.assumption'),
          // modify assumption to fit current operations needs
      primaryDistrict = this.get('primaryDistrict'),
          // needed here?
      multipliers = this.get('multipliers'),
          scenario = this.get('model');

      return sortedDistricts.map(function (district) {
        var isPrimary = primaryDistrict ? district.get('id') === primaryDistrict.get('id') : false,
            engineGroup = district.get('parentGroup');

        return _impactEngineCurrent.default.create({
          isPrimary: isPrimary,
          district: district,
          schedule: scenario.getScheduleForDistrict(engineGroup),
          assumption: assumption,
          scenario: _this2,
          className: 'index-' + (district ? district.get('id') : ''),
          group: engineGroup,
          multipliers: multipliers
        });
      });
    }),

    // combination of current operation calculations + expansion operation calculations (engines)
    // TODO: refactor these 3 engine properties to be more concise
    totalEngines: Ember.computed('sortedDistricts.@each', 'model.assumption', 'primaryDistrict', 'multipliers', 'incentiveSchedules.[]', 'currentEngines.@each.{totalBenefitsTotal,netBenefitsTotal}', 'expansionEngines.@each.{totalBenefitsTotal,netBenefitsTotal}', function () {
      var _this3 = this;

      var sortedDistricts = this.get('sortedDistricts'),
          assumption = this.get('model.assumption'),
          // modify assumption to fit current operations needs
      primaryDistrict = this.get('primaryDistrict'),
          // needed here?
      multipliers = this.get('multipliers'),
          scenario = this.get('model');

      return sortedDistricts.map(function (district, index) {
        var isPrimary = primaryDistrict ? district.get('id') === primaryDistrict.get('id') : false,
            engineGroup = district.get('parentGroup'),
            currentEngine = _this3.get('currentEngines').objectAt(index),
            expansionEngine = _this3.get('expansionEngines').objectAt(index);

        return _impactEngineTotal.default.create({
          isPrimary: isPrimary,
          district: district,
          schedule: scenario.getScheduleForDistrict(engineGroup),
          assumption: assumption,
          scenario: _this3,
          className: 'index-' + (district ? district.get('id') : ''),
          group: engineGroup,
          multipliers: multipliers,
          currentEngine: currentEngine,
          expansionEngine: expansionEngine
        });
      });
    }),

    firstThreeEngines: Ember.computed('engines.[]', function () {
      return this.get('engines').slice(0, 3);
    }),

    otherEngines: Ember.computed('engines.[]', function () {
      return this.get('engines').slice(3, this.get('engines.length'));
    }),

    primaryEngine: Ember.computed('engines.@each.isPrimary', function () {
      return this.get('engines').findBy('isPrimary', true);
    }),

    primaryEngines: Ember.computed('engines.@each.isPrimary', 'currentEngines.@each.isPrimary', 'expansionEngines.@each.isPrimary', 'totalEngines.@each.isPrimary', function () {
      return Ember.Object.create({
        selected: this.get('engines').findBy('isPrimary', true),
        current: this.get('currentEngines').findBy('isPrimary', true),
        expansion: this.get('expansionEngines').findBy('isPrimary', true),
        total: this.get('totalEngines').findBy('isPrimary', true)
      });
    }),

    // Only used to find the correct multiplier set to search through when filling out a data request
    multiplierSetForRequest: Ember.computed('usesStandardDistrictConfig', 'primaryDistrict', 'firstDistrictAreaDistrict', function () {

      var standardDistrictConfig = this.get('usesStandardDistrictConfig');

      if (standardDistrictConfig) {
        return this.get('primaryDistrict.multiplierSet.id');
      } else if (this.get('multiplierSets.length')) {
        // set in setupController hook
        // If there are districts for this client, their multiplier sets should be loaded in the model hook in the scenario-emailed route
        return this.get('multiplierSets.firstObject.id');
      } else if (this.get('firstDistrictAreaDistrict.multiplierSet')) {
        // On the off chance there are no districts associated with any groups for this client, we should have set the firstDistrictAreaDistrict prop in the route
        return this.get('firstDistrictAreaDistrict.multiplierSet.id');
      }
    }),

    selectedMultiplier: Ember.computed.reads('model.multiplier'),

    displayedMultipliers: Ember.computed('primaryDistrict.employmentPercentOfRegion', 'model.{multiplierCode,employmentMultiplier,earningsMultiplier,usesCustomMultipliers}', function () {
      var multiplierData = {
        earningsMultiplier: this.get('model.earningsMultiplier') || 1,
        employmentMultiplier: this.get('model.employmentMultiplier') || 1,
        multiplierCode: this.get('model.multiplierCode')
      };
      if (this.get('model.usesCustomMultipliers')) {
        return Ember.Object.create({
          employment: multiplierData.employmentMultiplier,
          earnings: multiplierData.earningsMultiplier
        });
      }
      return this.calculateDisplayedMultiplier(multiplierData);
    }),

    displayedCurrentMultipliers: Ember.computed('primaryDistrict.employmentPercentOfRegion', 'model.{currentMultiplierCode,currentEmploymentMultiplier,currentEarningsMultiplier}', function () {
      var multiplierData = {
        earningsMultiplier: this.get('model.currentEarningsMultiplier') || 1,
        employmentMultiplier: this.get('model.currentEmploymentMultiplier') || 1,
        multiplierCode: this.get('model.currentMultiplierCode')
      };
      return this.calculateDisplayedMultiplier(multiplierData);
    }),

    calculateDisplayedMultiplier: function calculateDisplayedMultiplier(_ref) {
      var _ref$earningsMultipli = _ref.earningsMultiplier,
          earningsMultiplier = _ref$earningsMultipli === undefined ? 1 : _ref$earningsMultipli,
          _ref$employmentMultip = _ref.employmentMultiplier,
          employmentMultiplier = _ref$employmentMultip === undefined ? 1 : _ref$employmentMultip,
          _ref$multiplierCode = _ref.multiplierCode,
          multiplierCode = _ref$multiplierCode === undefined ? 'Multiplier' : _ref$multiplierCode;

      var regionPercent = parseFloat(this.get('primaryDistrict.employmentPercentOfRegion')) || 1;
      var adjustedEmployment = (employmentMultiplier - 1) * 0.5 * (regionPercent + 1) + 1;
      var adjustedEarnings = (earningsMultiplier - 1) * 0.5 * (regionPercent + 1) + 1;

      return Ember.Object.create({
        employment: adjustedEmployment,
        earnings: adjustedEarnings,
        title: multiplierCode
      });
    },


    // current operations inputs based on primary district doesApplys
    currentOperationsCapitalInvestmentRows: Ember.computed('sections.current.currentProperty.content.firstObject', 'primaryAppliesLand', 'primaryAppliesBuildings', 'primaryAppliesFFE', 'primaryAppliesInventories', function () {
      var _this4 = this;

      var rows = this.get('sections.current.currentProperty.content.firstObject.rows');
      return rows.filter(function (row) {
        return _this4.get(row.scenarioIf);
      });
    }),

    jobsAddedEachYearInputs: Ember.computed('model.jobsAddedEachYear', 'years', function () {

      var jobsAddedEachYear = this.get('model.jobsAddedEachYear'),
          years = this.get('years'),
          column = void 0;

      column = _column.default.create({
        title: 'New Jobs Added',
        columnName: 'jobsAddedEachYear',
        content: (0, _makeInputs.default)(jobsAddedEachYear, years)
      });

      return [column];
    }),

    unboundJobsAddedEachYearInputs: Ember.computed(function () {
      return this.get('jobsAddedEachYearInputs');
    }),

    lastYearWithJobsAdded: Ember.computed('model.jobsAddedEachYear', 'years', function () {
      var jobs = (0, _floatMap.default)(this.get('model.jobsAddedEachYear')),
          // always uses expansion on purpose
      years = this.get('years');
      var nonZeroYear = 0;

      for (var i = 0; i < years; i++) {
        if (jobs[i] > 0) {
          nonZeroYear = i;
        }
      }

      return nonZeroYear + 1;
    }),

    newWorkersMovingAdjustment: Ember.computed('model.percentNewDirectWorkersMoving', 'primaryDistrict', 'primaryDistrict.percentNewDirectWorkersMoving', function () {

      var scenarioDistrictWorkers = this.get('model.percentNewDirectWorkersMoving'),
          primaryDistrict = this.get('primaryDistrict'),
          primaryDistrictWorkers = void 0,
          newWorkersAdjustment = 1;

      if (primaryDistrict && scenarioDistrictWorkers !== undefined && scenarioDistrictWorkers !== null) {
        primaryDistrictWorkers = parseFloat(primaryDistrict.get('percentNewDirectWorkersMoving'));
        newWorkersAdjustment = parseFloat(scenarioDistrictWorkers) / primaryDistrictWorkers;
      }

      return newWorkersAdjustment;
    }),

    workersRelocatingLabel: Ember.computed('primaryDistrict', function () {
      return 'Percent of New Direct Workers moving to ' + this.get('primaryDistrict.name');
    }),

    disabledNewWorkersMovingInputs: Ember.computed('currentUser.client.readOnlyAccess', 'model.usesCustomPercentNewDirectWorkersMoving', function () {
      return this.get('currentUser.client.readOnlyAccess') || !this.get('model.usesCustomPercentNewDirectWorkersMoving');
    }),

    usesStandardDistrictConfig: Ember.computed('client.districtConfigType', function () {
      var config = this.get('client.districtConfigType');
      return !config || config === 'standard';
    }),

    // Salaries
    averageAnnualSalariesInputs: Ember.computed('model.{averageAnnualSalaries,averageAnnualSalariesIncrease}', function () {

      var averageAnnualSalaries = this.get('model.averageAnnualSalaries'),
          increase = this.get('model.averageAnnualSalariesIncrease'),
          years = this.get('years');

      return _column.default.create({
        title: 'Avg Annual Salary',
        columnName: 'averageAnnualSalaries',
        inputType: 'dollars',
        isCascading: true,
        increase: (0, _makeIncrease.default)('averageAnnualSalariesIncrease', 'Percent Annual Increase', increase),
        content: (0, _makeInputs.default)(averageAnnualSalaries, years)
      });
    }),

    unboundAverageAnnualSalariesInputs: Ember.computed(function () {
      return this.get('averageAnnualSalariesInputs');
    }),

    extendSalaryInputs: Ember.observer('years', function () {

      if (this.get('model.isDeleted')) {
        return;
      }

      var averageAnnualSalaries = this.get('model.averageAnnualSalaries') || [],
          increase = parseFloat(this.get('model.averageAnnualSalariesIncrease')) + 1,
          years = this.get('years'),
          currentLength = averageAnnualSalaries.get('length'),
          i = void 0;

      for (i = currentLength; i < years; i++) {
        var last = averageAnnualSalaries[i - 1] || 0;
        averageAnnualSalaries[i] = (last * increase).toString();
      }

      this.set('model.averageAnnualSalaries', averageAnnualSalaries.slice(0, years));
    }),

    // aboveAverageSalary: computed.gt('primaryEngine.eiSummarySalaries.direct', 200000),

    aboveAverageSalary: Ember.computed('model.averageAnnualSalaries', function () {
      return this.get('model.averageAnnualSalaries').filter(function (salary) {
        return salary > 200000;
      });
    }),

    // Capital Investment
    capitalInvestmentInputs: Ember.computed('model.{capitalInvestmentLand,capitalInvestmentBuildings,capitalInvestmentFurniture}', 'primaryDistrict.{doesApplyPropertyTaxRateLand,doesApplyPropertyTaxRateBuildings,doesApplyPropertyTaxRateFFE}', 'usesStandardDistrictConfig', 'years', function () {

      var capitalInvestmentLand = this.get('model.capitalInvestmentLand'),
          capitalInvestmentBuildings = this.get('model.capitalInvestmentBuildings'),
          capitalInvestmentFurniture = this.get('model.capitalInvestmentFurniture'),
          doesApplyLand = this.get('primaryDistrict.doesApplyPropertyTaxRateLand'),
          doesApplyBuildings = this.get('primaryDistrict.doesApplyPropertyTaxRateBuildings'),
          doesApplyFFE = this.get('primaryDistrict.doesApplyPropertyTaxRateFFE'),
          standardConfig = this.get('usesStandardDistrictConfig'),
          years = this.get('years'),
          output = [],
          landColumn = void 0,
          buildingsColumn = void 0,
          furnitureColumn = void 0;

      if (doesApplyLand || !standardConfig) {
        landColumn = _column.default.create({
          title: 'Land',
          columnName: 'capitalInvestmentLand',
          inputType: 'dollars',
          content: (0, _makeInputs.default)(capitalInvestmentLand, years)
        });
        output.push(landColumn);
      }

      if (doesApplyBuildings || !standardConfig) {
        buildingsColumn = _column.default.create({
          title: 'Buildings & Other Real Property Improvements',
          columnName: 'capitalInvestmentBuildings',
          inputType: 'dollars',
          content: (0, _makeInputs.default)(capitalInvestmentBuildings, years)
        });
        output.push(buildingsColumn);
      }

      if (doesApplyFFE || !standardConfig) {
        furnitureColumn = _column.default.create({
          title: 'Furniture, Fixtures, & Equipment',
          columnName: 'capitalInvestmentFurniture',
          inputType: 'dollars',
          content: (0, _makeInputs.default)(capitalInvestmentFurniture, years)
        });

        output.push(furnitureColumn);
      }

      return output;
    }),

    unboundCapitalInvestmentInputs: Ember.computed('primaryDistrictDefined', function () {
      return this.get('capitalInvestmentInputs');
    }),

    hasCapitalInvestments: Ember.computed.gt('capitalInvestmentInputs.length', 0),

    slicedBuildings: Ember.computed('model.capitalInvestmentBuildings', 'years', function () {
      return (0, _floatMap.default)(this.get('model.capitalInvestmentBuildings')).slice(0, this.get('years'));
    }),

    slicedFurniture: Ember.computed('model.capitalInvestmentFurniture', 'years', function () {
      return (0, _floatMap.default)(this.get('model.capitalInvestmentFurniture')).slice(0, this.get('years'));
    }),

    // @TODO: make this change based on current or expansion selection in currentVsExpansion property
    // also this shouldnt always be based on primaryEngine, should it? it's used in the mini-report which changes with selected engine
    displayedCapitalInvestmentTotal: Ember.computed('primaryDistrict.{doesApplyPropertyTaxRateBuildings,doesApplyPropertyTaxRateFFE}', 'model.{capitalInvestmentBuildings,capitalInvestmentFurniture,assumption.buildingAndImprovementsAssociatedWithConstruction}', function () {
      var buildingsInvestment = this.get('slicedBuildings'),
          furnitureInvestment = this.get('slicedFurniture'),
          buildingsAssociatedWithConstruction = this.get('model.assumption.buildingAndImprovementsAssociatedWithConstruction'),
          doesApplyBuildings = !this.get('primaryDistrict') || this.get('primaryDistrict.doesApplyPropertyTaxRateBuildings'),
          doesApplyFurniture = !this.get('primaryDistrict') || this.get('primaryDistrict.doesApplyPropertyTaxRateFFE'),
          total = 0;

      if (buildingsInvestment.length && doesApplyBuildings && buildingsAssociatedWithConstruction) {
        total += (0, _sumArray.default)(buildingsInvestment);
      }

      if (furnitureInvestment.length && doesApplyFurniture) {
        total += (0, _sumArray.default)(furnitureInvestment);
      }

      return total;
    }),

    extendDirectCostRevenues: Ember.observer('years', function () {

      if (this.get('model.isDeleted')) {
        return;
      }

      var scenarioDirectCostRevs = this.get('model.directCostRevenues') || {},
          years = this.get('years');

      for (var id in scenarioDirectCostRevs) {

        var costRev = scenarioDirectCostRevs[id];

        if (!costRev.increase) {
          return;
        }

        var annual = costRev.annual,
            currentLength = annual.length,
            increase = parseFloat(costRev.increase) + 1;

        for (var i = currentLength; i < years; i++) {
          var last = annual[i - 1] || 0;
          annual[i] = (last * increase).toString();
        }

        scenarioDirectCostRevs[id].annual = annual.slice(0, years);
      }

      this.set('model.directCostRevenues', scenarioDirectCostRevs);
      this.notifyPropertyChange('directCostRevenuesChanged');
    }),

    sortedActiveDirectCostRevenues: Ember.computed.sort('activeDirectCostRevenues', 'directSorting'),

    hasDirectRevenues: Ember.computed.gt('directCostRevenueInputs.revenues.length', 0),
    hasDirectCosts: Ember.computed.gt('directCostRevenueInputs.costs.length', 0),

    directCostRevenueInputs: Ember.computed('model.directCostRevenues', 'sortedActiveDirectCostRevenues.@each', 'years', function () {
      var _this5 = this;

      var scenarioDirectCostRevs = this.get('model.directCostRevenues') || {},
          years = this.get('years'),
          activeDirect = this.get('sortedActiveDirectCostRevenues');

      // return array of objects we can loop through on template to make sections of inputs for each applicable cost/rev
      activeDirect = activeDirect.map(function (costRev, index) {

        var values = scenarioDirectCostRevs.hasOwnProperty(costRev.get('id')) ? scenarioDirectCostRevs[costRev.get('id')].annual : (0, _arrayOfLength.default)(years);
        var parentGroup = _this5.get('sortedDistricts').find(function (district) {
          return district.get('directCostRevenues').indexOf(costRev) > -1;
        }).get('parentGroup.label');
        var sectionTitle = (costRev.get('name') ? costRev.get('name') : 'Untitled') + ' - ' + parentGroup + (costRev.get('type') === 'cost' ? ' Cost' : ''); // this is an ugly ternary
        var tabIndex = (costRev.get('type') === 'cost' ? '11' : '6') + index.toString();
        var newSection = { title: sectionTitle, costRev: costRev, tabIndex: tabIndex }; // this will be returned in this map
        var newColumn = void 0;

        _this5.get('collapsibleSections').set(costRev.get('id') + 'isHidden', true);

        newColumn = _column.default.create({
          title: _string2.default.upperFirst(costRev.get('type')) + ' Amount',
          inputType: 'dollars',
          costRev: costRev
        });

        if (costRev.get('isCascading')) {
          var increase = void 0;
          if (scenarioDirectCostRevs.hasOwnProperty(costRev.get('id')) && scenarioDirectCostRevs[costRev.get('id')].increase) {
            increase = scenarioDirectCostRevs[costRev.get('id')].increase;
          } else {
            increase = costRev.get('annualPercentIncrease');
          }
          newColumn.setProperties({
            isCascading: true,
            increase: (0, _makeIncrease.default)('directCostRevenueIncrease', 'Percent Annual Increase', increase, costRev.get('id') + 'disabled'),
            content: (0, _makeInputs.default)(values, years)
          });

          newSection.column = newColumn;
          return newSection;
        }

        newColumn.setProperties({
          content: (0, _makeInputs.default)(values, years)
        });

        newSection.column = [newColumn];
        return newSection;
      });

      return Ember.Object.create({
        revenues: activeDirect.filterBy('costRev.type', 'revenue'),
        costs: activeDirect.filterBy('costRev.type', 'cost')
      });
    }),

    unboundDirectCostRevenueInputs: Ember.computed(function () {
      return this.get('directCostRevenueInputs');
    }),

    buildingPermitsFeesDistricts: Ember.computed('sortedDistricts.@each.doesApplyBuildingPermitsAndFees', function () {
      return this.get('sortedDistricts').filterBy('doesApplyBuildingPermitsAndFees');
    }),

    buildingPermitsFeesInputs: Ember.computed('buildingPermitsFeesDistricts', 'buildingPermitsFeesDistricts.@each.parentGroup', 'model.buildingPermitsFees', 'years', function () {
      var _this6 = this;

      var scenarioBuildingPermitsFees = this.get('model.buildingPermitsFees') || {},
          years = this.get('years');

      return this.get('buildingPermitsFeesDistricts').map(function (district, index) {

        var parentGroupLabel = district.get('parentGroup.label');
        var values = scenarioBuildingPermitsFees.hasOwnProperty(district.get('id')) ? scenarioBuildingPermitsFees[district.get('id')].annual : (0, _arrayOfLength.default)(years);
        var newColumn = _column.default.create({
          title: 'Amount paid to ' + parentGroupLabel,
          columnName: 'buildingPermitsFees',
          inputType: 'dollars',
          content: (0, _makeInputs.default)(values, years),
          district: district
        });

        // collapse this section for normal scenarios, open it for requests
        _this6.get('collapsibleSections').set('bpf' + district.get('id') + 'isHidden', !_this6.get('model.isRequest'));

        return {
          title: 'Building Permits & Fees - ' + parentGroupLabel,
          column: [newColumn],
          district: district,
          tabIndex: '5' + index.toString()
        };
      });
    }),

    unboundBuildingPermitsFeesInputs: Ember.computed(function () {
      return this.get('buildingPermitsFeesInputs');
    }),

    taxableInventoriesInputs: Ember.computed('model.{taxableInventories,taxableInventoriesAnnualIncrease}', 'years', function () {

      var taxableInventories = this.get('model.taxableInventories'),
          increase = this.get('model.taxableInventoriesAnnualIncrease'),
          years = this.get('years');

      return _column.default.create({
        title: 'Amount',
        columnName: 'taxableInventories',
        inputType: 'dollars',
        isCascading: true,
        increase: (0, _makeIncrease.default)('taxableInventoriesAnnualIncrease', 'Percent Annual Increase', increase),
        content: (0, _makeInputs.default)(taxableInventories, years)
      });
    }),

    unboundTaxableInventoriesInputs: Ember.computed(function () {
      return this.get('taxableInventoriesInputs');
    }),

    extendTaxableInventoriesInputs: Ember.observer('years', function () {

      if (this.get('model.isDeleted')) {
        return;
      }

      var taxableInventories = this.get('model.taxableInventories') || [],
          increase = parseFloat(this.get('model.taxableInventoriesAnnualIncrease')) + 1,
          years = this.get('years'),
          currentLength = taxableInventories.get('length'),
          i = void 0;

      for (i = currentLength; i < years; i++) {
        var last = taxableInventories[i - 1] || 0;
        taxableInventories[i] = (last * increase).toString();
      }

      this.set('model.taxableInventories', taxableInventories.slice(0, years));
    }),

    taxablePurchasesInputs: Ember.computed('model.{taxablePurchases,taxablePurchasesPercentIncrease}', 'primaryGroupName', 'years', function () {

      var taxablePurchases = this.get('model.taxablePurchases'),
          taxablePurchasesPercentIncrease = this.get('model.taxablePurchasesPercentIncrease'),
          years = this.get('years');

      return _column.default.create({
        title: 'Amount in the ' + this.get('primaryGroupName'),
        columnName: 'taxablePurchases',
        inputType: 'dollars',
        isCascading: true,
        increase: (0, _makeIncrease.default)('taxablePurchasesPercentIncrease', 'Percent Annual Increase', taxablePurchasesPercentIncrease),
        content: (0, _makeInputs.default)(taxablePurchases, years)
      });
    }),

    unboundTaxablePurchasesInputs: Ember.computed(function () {
      return this.get('taxablePurchasesInputs');
    }),

    extendTaxablePurchasesInputs: Ember.observer('years', function () {

      if (this.get('model.isDeleted')) {
        return;
      }

      var taxablePurchases = this.get('model.taxablePurchases') || [],
          increase = parseFloat(this.get('model.taxablePurchasesPercentIncrease')) + 1,
          years = this.get('years'),
          currentLength = taxablePurchases.get('length'),
          i = void 0;

      for (i = currentLength; i < years; i++) {
        var last = taxablePurchases[i - 1] || 0;
        taxablePurchases[i] = (last * increase).toString();
      }

      this.set('model.taxablePurchases', taxablePurchases.slice(0, years));
    }),

    taxableSalesInputs: Ember.computed('model.{taxableSales,taxableSalesPercentIncrease}', 'primaryGroupName', 'years', function () {

      var taxableSales = this.get('model.taxableSales'),
          taxableSalesPercentIncrease = this.get('model.taxableSalesPercentIncrease'),
          years = this.get('years');

      return _column.default.create({
        title: 'Amount in the ' + this.get('primaryGroupName'),
        columnName: 'taxableSales',
        inputType: 'dollars',
        isCascading: true,
        increase: (0, _makeIncrease.default)('taxableSalesPercentIncrease', 'Percent Annual Increase', taxableSalesPercentIncrease),
        content: (0, _makeInputs.default)(taxableSales, years)
      });
    }),

    unboundTaxableSalesInputs: Ember.computed(function () {
      return this.get('taxableSalesInputs');
    }),

    extendTaxableSalesInputs: Ember.observer('years', function () {
      if (this.get('model.isDeleted')) {
        return;
      }

      var taxableSales = this.get('model.taxableSales') || [],
          increase = parseFloat(this.get('model.taxableSalesPercentIncrease')) + 1,
          years = this.get('years'),
          currentLength = taxableSales.get('length'),
          i = void 0;

      for (i = currentLength; i < years; i++) {
        var last = taxableSales[i - 1] || 0;
        taxableSales[i] = (last * increase).toString();
      }

      this.set('model.taxableSales', taxableSales.slice(0, years));
    }),

    extendHotelInputs: Ember.observer('years', function () {
      if (this.get('model.isDeleted')) {
        return;
      }

      var hotelNights = this.get('model.hotelNights') || [],
          hotelDailyRate = this.get('model.hotelDailyRate') || [],
          spendingPerHotelNight = this.get('model.spendingPerHotelNight') || [],
          hotelNightsIncrease = parseFloat(this.get('model.hotelNightsIncrease')) + 1,
          hotelDailyRateIncrease = parseFloat(this.get('model.hotelDailyRateIncrease')) + 1,
          spendingPerHotelNightIncrease = parseFloat(this.get('model.spendingPerHotelNightIncrease')) + 1,
          years = this.get('years');

      var extendLength = function extendLength(array, increase, years) {
        var currentLength = array.get('length');

        for (var i = currentLength; i < years; i++) {
          var last = array[i - 1] || 0;
          array[i] = last * increase;
        }

        return array;
      };

      this.set('model.hotelNights', extendLength(hotelNights, hotelNightsIncrease, years));
      this.set('model.hotelDailyRate', extendLength(hotelDailyRate, hotelDailyRateIncrease, years));
      this.set('model.spendingPerHotelNight', extendLength(spendingPerHotelNight, spendingPerHotelNightIncrease, years));
    }),

    tifIncentives: Ember.computed('tifDistrict', 'model.{tifIncentive.incentives,assumption.scenarioLength,jobsAddedEachYear}', 'tifAnnual', function () {
      var schedule = this.get('model.tifIncentive'),
          tifDistrict = this.get('tifDistrict');

      if (!schedule || !tifDistrict) {
        return;
      }

      return _tifIncentiveEngine.default.create({
        assumption: this.get('model.assumption'),
        schedule: schedule,
        jobs: (0, _sumArray.default)((0, _floatMap.default)(this.get('model.jobsAddedEachYear'))),
        annualNetBenefits: this.get('tifAnnual'),
        incentiveComparison: 'nonTax',
        scenario: this
      });
    }),

    tifContributionAnnualLessIncentives: Ember.computed('tifAnnual', 'tifIncentives.incentives', function () {
      var _this7 = this;

      return this.get('tifAnnual').map(function (tif, index) {
        var incentive = 0;
        if (_this7.get('tifIncentives') && _this7.get('tifIncentives.incentives')) {
          incentive = _this7.get('tifIncentives.incentives')[index] || 0;
        }
        return tif - incentive;
      });
    }),

    netPresentValueTifContributionsLessIncentives: Ember.computed('tifContributionAnnualLessIncentives', 'model.assumption.discountForCalculatingPresentValueCostsBenefits', function () {
      var annualTifContributionsLessIncentives = this.get('tifContributionAnnualLessIncentives'),
          discountRate = parseFloat(this.get('model.assumption.discountForCalculatingPresentValueCostsBenefits')),
          annual = void 0,
          total = void 0;

      annual = annualTifContributionsLessIncentives.map(function (benefit, index) {
        return benefit / Math.pow(1 + discountRate, index + 1);
      });

      total = (0, _sumArray.default)(annual);

      return Ember.Object.create({ annual: annual, total: total });
    }),

    tifTotals: Ember.computed('engines.@each.{tifContributionTotal,netPresentValueTifContributionsTotal}', 'tifIncentives.totalIncentive', 'engines', function () {

      var engines = this.get('engines'),
          tifIncentives = this.get('tifIncentives'),
          benefit = void 0,
          incentive = void 0,
          netLessIncentives = void 0,
          netPresentValue = void 0,
          netPresentValueLessIncentives = void 0;

      benefit = engines.reduce(function (total, engine) {
        return total + engine.get('tifContributionTotal');
      }, 0);

      // negative number
      incentive = tifIncentives ? tifIncentives.get('totalIncentiveSummaryReport') : 0, netLessIncentives = benefit + incentive;

      netPresentValue = engines.reduce(function (total, engine) {
        return total + engine.get('netPresentValueTifContributionsTotal');
      }, 0);

      netPresentValueLessIncentives = this.get('netPresentValueTifContributionsLessIncentives.total') || 0;

      return Ember.Object.create({
        benefit: benefit,
        cost: 0,
        net: benefit,
        incentive: incentive,
        netLessIncentives: netLessIncentives,
        netPresentValue: netPresentValue,
        netPresentValueLessIncentives: netPresentValueLessIncentives
      });
    }),

    // return array of n-years length representing each year's total tifContributions across all districts
    tifAnnual: Ember.computed('engines.@each.{tifContributionTotal,tifContributionAnnual}', function () {
      // array of arrays
      var annuals = this.get('engines').map(function (engine) {
        return engine.get('tifContributionTotals.annual');
      });

      return (0, _sumManyArrays.default)(annuals);
    }),

    tifOtherEnginesAnnual: Ember.computed('otherEngines.@each', 'engines.@each.{tifContributionTotal,tifContributionAnnual}', 'years', function () {
      var contributions = this.get('otherEngines').map(function (engine) {
        return engine.get('tifContributionTotals.annual');
      });

      return (0, _addEach.default)(contributions) || [];
    }),

    tifOtherEnginesTotal: Ember.computed('tifOtherEnginesAnnual', function () {
      return (0, _sumArray.default)(this.get('tifOtherEnginesAnnual'));
    }),

    tifCumulativeAnnual: Ember.computed('tifAnnual', function () {
      return (0, _cumulativeSum.default)(this.get('tifAnnual'));
    }),

    aggregateTifContributionTotals: Ember.computed('engines.@each.{tifContributionTotal,realPropertyTifContributions}', function () {
      var engines = this.get('engines');
      return Ember.Object.create({
        land: (0, _aggregateProps.getAggregateArrayProperty)(engines, 'realPropertyTifContributions.land'),
        buildings: (0, _aggregateProps.getAggregateArrayProperty)(engines, 'realPropertyTifContributions.buildings'),
        furniture: (0, _aggregateProps.getAggregateArrayProperty)(engines, 'ffeTifContributions'),
        inventory: (0, _aggregateProps.getAggregateArrayProperty)(engines, 'inventoryTifContributions'),
        taxableSales: (0, _aggregateProps.getAggregateArrayProperty)(engines, 'taxableSalesTifContributions'),
        annual: (0, _aggregateProps.getAggregateArrayProperty)(engines, 'tifContributionTotals')
      });
    }),

    // Faux ImpactEngine for participating TIF district so it can be treated the same as other participating community districts
    // Used in community incentives and community report hwen TIF is included
    tifImpactEngine: Ember.computed('model.tifIncentive', 'model.assumption', 'tifDistrict', 'multipliers', function () {

      return _tifImpactEngine.default.create({
        district: Ember.Object.create({
          name: this.get('tifDistrict.name'),
          multiplierSet: this.get('primaryDistrict.multiplierSet') // need multipliers for jobs calculations
        }),
        schedule: this.get('model.tifIncentive'),
        assumption: this.get('model.assumption'),
        scenario: this,
        group: Ember.Object.create({
          label: 'TIF'
        }),
        multipliers: this.get('multipliers')
      });
    }),

    totals: Ember.computed('engines.@each.{totalBenefitsTotal,totalCostsTotal,netBenefitsTotal,totalIncentivesTotal,netBenefitsLessIncentivesTotal,netPresentValueNetBenefitsTotal,netPresentValueNetBenefitsLessIncentivesTotal}', 'tifTotals.@each.{benefit,cost,net,netPresentValue}', function () {
      var engines = this.get('engines'),
          tifTotals = this.get('tifTotals'),
          benefit = void 0,
          cost = void 0,
          incentive = void 0,
          net = void 0,
          netLessIncentives = void 0,
          netPresentValue = void 0,
          netPresentValueLessIncentives = void 0;

      benefit = engines.reduce(function (total, engine) {
        return total + engine.get('totalBenefitsTotal');
      }, 0);
      benefit += tifTotals.get('benefit');

      cost = engines.reduce(function (total, engine) {
        return total + engine.get('totalCostsTotal');
      }, 0);

      incentive = engines.reduce(function (total, engine) {
        return total + engine.get('totalIncentivesTotal');
      }, 0);
      incentive += tifTotals.get('incentive');

      net = engines.reduce(function (total, engine) {
        return total + engine.get('netBenefitsTotal');
      }, 0);
      net += tifTotals.get('net');

      netLessIncentives = engines.reduce(function (total, engine) {
        return total + engine.get('netBenefitsLessIncentivesTotal');
      }, 0);
      netLessIncentives += tifTotals.get('netLessIncentives');

      netPresentValue = engines.reduce(function (total, engine) {
        return total + engine.get('netPresentValueNetBenefitsTotal');
      }, 0);
      netPresentValue += tifTotals.get('netPresentValue');

      netPresentValueLessIncentives = engines.reduce(function (total, engine) {
        return total + engine.get('netPresentValueNetBenefitsLessIncentivesTotal');
      }, 0);
      netPresentValueLessIncentives += tifTotals.get('netPresentValueLessIncentives');

      return { benefit: benefit, cost: cost, incentive: incentive, net: net, netLessIncentives: netLessIncentives, netPresentValue: netPresentValue, netPresentValueLessIncentives: netPresentValueLessIncentives };
    }),

    publicSupportTotals: Ember.computed('expansionEngines.@each.{totalPropertyTaxAbated,allSalesTaxRebatesTotal,totalHotelTaxRebates,nonTaxIncentiveTotal}', 'tifIncentives.totalIncentive', 'model.usesTifDistrict', function () {
      var engines = this.get('expansionEngines'),
          abatements = (0, _sumArray.default)(engines.mapBy('totalPropertyTaxAbated')),
          rebates = (0, _sumArray.default)(engines.mapBy('allSalesTaxRebatesTotal')),
          hotelRebates = (0, _sumArray.default)(engines.mapBy('totalHotelTaxRebates')),
          incentives = (0, _sumArray.default)(engines.mapBy('nonTaxIncentiveTotal')),
          tifIncentives = this.get('tifIncentives.totalIncentive');

      if (tifIncentives && this.get('model.usesTifDistrict')) {
        incentives += tifIncentives;
      }

      return Ember.Object.create({
        abatements: abatements,
        rebates: rebates,
        hotelRebates: hotelRebates,
        incentives: incentives,
        total: abatements + rebates + hotelRebates + incentives
      });
    }),

    summaryDistributionGraphData: Ember.computed('abbreviatedDistributionOfNetBenefits', function () {
      var data = this.get('abbreviatedDistributionOfNetBenefits');
      // const widths = this.get('widthPercentages');

      return data.map(function (district) {
        // district.width = widths[i];
        district.amount = district.value.toString();

        return district;
      });
    }),

    abbreviatedDistributionOfNetBenefits: Ember.computed('distributionOfNetBenefits', function () {
      var benefits = this.get('distributionOfNetBenefits');
      var otherNetBenefits = void 0,
          reducer = void 0,
          otherNetBenefitsTotal = void 0,
          abbreviatedBenefits = void 0;

      if (benefits.length <= 3) {
        return benefits;
      }

      otherNetBenefits = this.get('distributionOfNetBenefits').slice(3);
      reducer = function reducer(accumulator, currentValue) {
        return { value: accumulator.value + currentValue.value, name: currentValue.abbreviatedName };
      };
      otherNetBenefitsTotal = otherNetBenefits.reduce(reducer);
      otherNetBenefitsTotal.abbreviatedName = otherNetBenefits.length === 1 ? otherNetBenefits[0].abbreviatedName : 'Other';
      abbreviatedBenefits = this.get('distributionOfNetBenefits').slice(0, 3);
      abbreviatedBenefits.push(otherNetBenefitsTotal);
      return abbreviatedBenefits;
    }),

    distributionOfNetBenefits: Ember.computed('engines.@each.{netBenefitsTotal,netBenefitsLessIncentivesTotal}', 'model.usesTifDistrict', 'engines', 'tifDistrict', 'tifTotals.{netLessIncentives,net}', function () {

      var tifDistrict = this.get('tifDistrict'),
          abatementsAsCosts = this.get('model.assumption.abatementsAsCosts'),
          netBenefits = void 0,
          graphData = void 0;

      graphData = this.get('engines').map(function (engine) {

        netBenefits = abatementsAsCosts ? engine.get('netBenefitsLessIncentivesTotal') : engine.get('netBenefitsTotal');

        return {
          value: isNaN(netBenefits) ? 0 : netBenefits,
          name: engine.get('district.name'),
          abbreviatedName: engine.get('group.label')
        };
      });

      if (tifDistrict && this.get('model.usesTifDistrict')) {
        graphData.pushObject({
          value: abatementsAsCosts ? this.get('tifTotals.netLessIncentives') : this.get('tifTotals.net'),
          name: tifDistrict.get('name'),
          abbreviatedName: 'TIF District'
        });
      }

      return graphData;
    }),

    scenarioHasIncentives: Ember.computed('publicSupportTotals.incentives', 'hasPositiveNetBenefits', function () {
      return this.get('publicSupportTotals.incentives') > 0 && this.get('hasPositiveNetBenefits');
    }),

    hasPositiveNetBenefits: Ember.computed.gt('totals.net', 0),

    scenarioHasRebates: Ember.computed('engines.@each.allSalesTaxRebatesTotal', function () {
      return (0, _sumArray.default)(this.get('engines').mapBy('allSalesTaxRebatesTotal')) > 0;
    }),

    scenarioHasAbatements: Ember.computed.gt('abatementsPerProperty.total', 0),

    scenarioHasHotelRebates: Ember.computed('engines.@each.totalHotelTaxRebates', function () {
      return (0, _sumArray.default)(this.get('engines').mapBy('totalHotelTaxRebates')) > 0;
    }),

    reportHasPublicSupport: Ember.computed.or('scenarioHasAbatements', 'scenarioHasIncentives', 'scenarioHasRebates', 'scenarioHasHotelRebates', 'noAllocationCommunityIncentive'),

    abatementsPerProperty: Ember.computed('engines.@each.{propertyTaxLandAbated,propertyTaxBuildingsAbated,propertyTaxFurnitureAbated,propertyTaxInventoriesAbated,district}', function () {
      var grandLandTotal = 0,
          grandBuildingsTotal = 0,
          grandFurnitureTotal = 0,
          grandInventoriesTotal = 0,
          landTotal = 0,
          buildingsTotal = 0,
          furnitureTotal = 0,
          inventoriesTotal = 0,
          engineTotals = [];

      this.get('engines').forEach(function (engine) {

        var engineObject = {
          name: engine.get('district.name'),
          values: []
        },
            engineTotal = 0;

        landTotal = parseFloat(engine.get('propertyTaxLandAbated.total'));
        grandLandTotal += landTotal;
        engineTotal += landTotal;
        engineObject.values.push(landTotal);

        buildingsTotal = parseFloat(engine.get('propertyTaxBuildingsAbated.total'));
        grandBuildingsTotal += buildingsTotal;
        engineTotal += buildingsTotal;
        engineObject.values.push(buildingsTotal);

        furnitureTotal = parseFloat(engine.get('propertyTaxFurnitureAbated.total'));
        grandFurnitureTotal += furnitureTotal;
        engineTotal += furnitureTotal;
        engineObject.values.push(furnitureTotal);

        inventoriesTotal = parseFloat(engine.get('propertyTaxInventoriesAbated.total'));
        grandInventoriesTotal += inventoriesTotal;
        engineTotal += inventoriesTotal;
        engineObject.values.push(inventoriesTotal);

        engineObject.values.push(engineTotal);
        engineTotals.push(engineObject);
      });

      return Ember.Object.create({
        landTotal: grandLandTotal,
        buildingsTotal: grandBuildingsTotal,
        furnitureTotal: grandFurnitureTotal,
        inventoriesTotal: grandInventoriesTotal,
        engineTotals: engineTotals,
        total: grandLandTotal + grandBuildingsTotal + grandFurnitureTotal + grandInventoriesTotal
      });
    }),

    // why are we doing this for only the primary abatement schedule?
    // we should check the necessity of this adjustment
    adjustAbatementSchedules: Ember.observer('years', function () {
      var _this8 = this;

      var existingSchedules = ['abatementScheduleLand', 'abatementScheduleBuildings', 'abatementScheduleFurniture', 'abatementScheduleInventories'];

      if (!this.get('primarySchedule')) {
        return;
      }

      existingSchedules.forEach(function (schedule) {
        var existingSchedule = _this8.get('primarySchedule.' + schedule) || [],
            adjustedSchedule = existingSchedule,
            i = void 0;

        for (i = existingSchedule.length; i < _this8.get('years'); i++) {
          adjustedSchedule[i] = existingSchedule[i] || '0';
        }
        _this8.set('primarySchedule.' + schedule, adjustedSchedule);
      });
    }),

    primarySchedule: Ember.computed('model.activeSchedules.@each.districtGroup', function () {
      return this.get('model.activeSchedules').findBy('districtGroup.isPrimary', true);
    }),

    // keep this around in case we want to use dropdown on non tax incentives section

    // incentiveComparisonOptions: [
    //   EmberObject.create({
    //     val: 'total',
    //     text: 'Total Incentive vs. Net Benefits'
    //   }),
    //   EmberObject.create({
    //     val: 'nonTax',
    //     text: 'Non-Tax Incentive vs. Net Benefits'
    //   }),
    // ],

    incentiveComparisonInput: Ember.computed('model.assumption.abatementsAsCosts', function () {
      return this.get('model.assumption.abatementsAsCosts') ? 'total' : 'nonTax';
    }),

    incentiveGraphTitle: Ember.computed('incentiveComparisonInput', 'model.assumption.abatementsAsCosts', function () {
      if (this.get('incentiveComparisonInput') === 'total' && this.get('model.assumption.abatementsAsCosts')) {
        return 'Total Incentive vs. Net Benefits';
      } else if (this.get('model.assumption.abatementsAsCosts') && this.get('incentiveComparisonInput') === 'nonTax') {
        return 'Non-Tax Incentive vs. Net Benefits';
      } else {
        return 'Incentives vs. Net Benefits';
      }
    }),

    activeDistricts: Ember.computed('sortedDistrictGroups', 'sortedDistrictGroups.@each.districts', function () {
      var districtGroups = this.get('sortedDistrictGroups'),
          activeDistricts = [],
          isActive = void 0;

      if (districtGroups) {
        districtGroups.forEach(function (group) {
          group.get('districts').forEach(function (district) {
            isActive = district.get('isActive');
            if (isActive === undefined || isActive) {
              activeDistricts.push(district);
            }
          });
        });
      }

      return activeDistricts;
    }),

    usesInactiveDistricts: Ember.computed('activeDistricts.[]', 'model.districts', 'cloningDistrict', function () {
      var scenarioDistricts = this.get('model.districts'),
          activeDistricts = this.get('activeDistricts') || [],
          usesInactiveDistricts = false;

      if (this.get('currentUser.isNotDemo') && !this.get('cloningDistrict') && scenarioDistricts.get('length')) {
        scenarioDistricts.forEach(function (district) {
          if (activeDistricts.indexOf(district) < 0) {
            usesInactiveDistricts = true;
          }
        });
      }

      return usesInactiveDistricts;
    }),

    usesModifiedDistricts: Ember.computed('districts', function () {
      var modifiedDistricts = void 0;

      modifiedDistricts = this.get('districts').filter(function (district) {
        return district.get('adminCreated') === false;
      });

      return modifiedDistricts.length > 0;
    }),

    usesAnyModifiedAssumptions: Ember.computed.or('usesModifiedDistricts', 'model.usesModifiedAssumptions'),

    isScenarioDisabled: Ember.computed('currentUser.client.readOnlyAccess', 'model.finalized', function () {
      return !!(this.get('currentUser.client.readOnlyAccess') || this.get('model.finalized') || this.get('model.isRequest') && this.get('currentUser'));
    }),

    startingCalendarYearDisabled: Ember.computed('assumptionInputsDisabled', 'model.assumption.calendarYearDisplay', function () {
      return this.get('assumptionInputsDisabled') || !this.get('model.assumption.calendarYearDisplay');
    }),

    assumptionInputsDisabled: Ember.computed('currentUser.client.readOnlyAccess', 'model.assumption.inputsDisabled', function () {
      return !!(this.get('currentUser.client.readOnlyAccess') || this.get('model.assumption.inputsDisabled'));
    }),

    isSavable: Ember.computed('model.isAdminUnlocked', 'currentUser', function () {
      if (this.get('currentUser.isSuperAdmin')) {
        return this.get('model.isAdminUnlocked');
      }
      return this.get('currentUser') || this.get('model.isRequest');
    }),

    totalIncentiveIsDisabled: false,
    incentivePerJobIsDisabled: false,
    rateOfReturnIsDisabled: false,
    paybackPeriodIsDisabled: false,

    // make description modal open automatically if walkthrough isn't completed
    hideScenarioDescriptionModalHidden: Ember.observer('model.walkthroughCompleted', function () {
      var _this9 = this;

      var walkthroughCompleted = this.get('model.walkthroughCompleted');

      // hide if null or undefined for old scenarios
      var shouldHide = walkthroughCompleted || _lang2.default.isUndefined(walkthroughCompleted) || walkthroughCompleted === null;
      Ember.run.later(function () {
        _this9.set('scenarioDescriptionModalHidden', shouldHide);
      }, 50); // timeout ensures we see a change in the isHidden property needed to focus on inputs within the modal
    }),

    marketValueFFE: Ember.computed('model.assumption.percentMarketValueFFE.@each', function () {
      var currentValues = this.get('model.assumption.percentMarketValueFFE') || [];

      return currentValues.map(function (value, index) {
        return Ember.Object.create({
          val: value,
          label: 'Year ' + (index + 1),
          index: index
        });
      });
    }),

    unboundMarketValueFFE: Ember.computed(function () {
      return this.get('marketValueFFE');
    }),

    // used to show or hide certain Calls to Action throughout a scenario
    // we only want these to appear for essentials clients viewing essentials scenarios
    canRequestEssentialsUpgrade: Ember.computed('model.essentialsScenario', 'client.essentialsClient', 'currentUser.isSuperAdmin', function () {
      return this.get('model.essentialsScenario') && this.get('client.essentialsClient') && !this.get('currentUser.isSuperAdmin');
    }),

    // use this property to show a prompt when the user is able to update an essentials scenario
    // always allow for super admins, or for clients who are pro users viewing an essentials scenario
    canUpgradeEssentialsScenario: Ember.computed('model.essentialsScenario', 'districtGroups.length', 'anySelectableDistricts', 'client.{essentialsClient,readOnlyAccess}', 'currentUser.isSuperAdmin', function () {
      var isEssentialsScenario = !!this.get('model.essentialsScenario');
      var isEssentialsClient = !!this.get('client.essentialsClient');
      var isReadOnlyClient = !!this.get('client.readOnlyAccess');
      var isSuperAdmin = !!this.get('currentUser.isSuperAdmin');
      var hasDistrictGroups = !!this.get('districtGroups.length');
      var hasTaxDistricts = !!this.get('anySelectableDistricts');

      // option only applies to essentials scenarios
      if (!isEssentialsScenario) return false;

      // super admins can always see this prompt, but may get warning about groups/districts
      if (isSuperAdmin) return true;

      // clients who are not essentials clients and not read only can see this is there are districts/groups
      return !isEssentialsClient && !isReadOnlyClient && hasDistrictGroups && hasTaxDistricts;
    }),

    essentialsPromptNotCleared: true,

    showEssentialsUpgradePrompt: Ember.computed.and('canUpgradeEssentialsScenario', 'essentialsPromptNotCleared'),

    updateScenarioDistrict: function updateScenarioDistrict(newDistrict, group) {
      var scenario = this.get('model'),
          canSave = !this.get('demoUser') && this.get('isSavable');

      if (newDistrict) {
        scenario.get('districts').pushObject(newDistrict);
      }

      var hasNewDistrict = !!newDistrict;
      var schedule = scenario.getScheduleForDistrict(group);
      if (!schedule) {
        schedule = this.store.createRecord('incentiveSchedule', {
          districtGroup: group,
          isActive: hasNewDistrict
        });
        scenario.get('incentiveSchedules').pushObject(schedule);
        this.set('districtAreasWorking', false);
        return canSave ? schedule.save() : Ember.RSVP.resolve();
      }

      // this will send a PUT for an incentive schedule AND scenario
      schedule.set('isActive', hasNewDistrict);
      return canSave ? schedule.save() : Ember.RSVP.resolve();
    },

    changeSelectedSummaryEngine: function changeSelectedSummaryEngine(newDistrict) {
      var summarySelectedDistrict = this.get('model.summarySelectedDistrict'),
          sortedDistricts = this.get('sortedDistricts'),
          groups = this.get('sortedDistrictGroups'),
          foundSummarySelected = void 0;

      // look for summarySelectedDistrict in list of sorted districts
      // if found, means don't change summary selected because it's still valid
      foundSummarySelected = sortedDistricts.findBy('id', summarySelectedDistrict);
      if (foundSummarySelected) {
        return;
      }

      if (!foundSummarySelected && newDistrict) {
        // if we have a newDistrict AND the summarySelectedDistrict is no longer found in sortedDistricts, change to newDistrict
        this.set('model.summarySelectedDistrict', newDistrict.get('id'));
        this.set('selectedSchedule', this.get('model').getSelectedSchedule(groups));
      } else if (!foundSummarySelected && !newDistrict && sortedDistricts.get('length')) {
        // if summarySelectedDistrict is not within sortedDistricts, set summarySelected to first sortedDistrict? (selected N/A)
        this.set('model.summarySelectedDistrict', sortedDistricts.get('firstObject.id'));
        this.set('selectedSchedule', this.get('model').getSelectedSchedule(groups));
      }
    },

    // This might be unused
    projectLocation: Ember.computed('model.projectLocation', 'client.{city,state}', function () {
      var projectLocation = this.get('model.projectLocation');
      var clientCity = this.get('client.city');
      var clientState = this.get('client.state');

      if (projectLocation) {
        return projectLocation;
      }

      if (clientCity && clientState) {
        return clientCity + ', ' + clientState;
      } else {
        return '';
      }
    }),

    // automatically toggle Expansion vs Total scenario option if new business is selected
    // isNewBusiness can be selected from scenario basics modal, scenario details page, or fact-sheet report
    toggleExpansionVsTotalView: Ember.observer('model.isNewBusiness', function () {
      if (this.get('model.isNewBusiness')) {
        this.set('model.currentVsExpansion', 'expansion');
      }
      this.showFirstSectionOnly();
    }),

    actions: {
      onAddressSelect: function onAddressSelect(address) {
        address = address ? address.formatted_address : this.get('model.projectLocation');
        this.set('model.projectLocation', address);
        this.send('save', 'projectLocation', address);
      },
      showNextModal: function showNextModal(hideNextModal) {
        this.set(hideNextModal, false);
      },
      save: function save(name, values, forceSave) {

        var model = this.get('model');

        if (name && values) {
          values = (0, _stringify.default)(values);
          model.set(name, values);
        }

        if (!this.get('currentUser.isDemo') && this.get('isSavable') || forceSave) {
          model.save();
        }
      },
      saveAllRequest: function saveAllRequest() {
        var _this10 = this;

        var scenarioPromise = this.get('model').save();
        var requestPromise = this.get('scenarioDataRequest').save();
        Ember.RSVP.all([scenarioPromise, requestPromise]).then(function () {
          _this10.get('notify').info('Request Saved');
        }, this.get('adapterErrorNotifier').createErrorsAlerter());
      },
      saveRequest: function saveRequest() {
        if (this.get('isSavable')) {
          this.get('scenarioDataRequest').save();
        }
      },
      updateScenarioTitle: function updateScenarioTitle(subtitle) {
        if (!subtitle) {
          var indexedScenarios = this.get('project.indexedScenariosOnly');
          var currentIndexedScenario = indexedScenarios.findBy('id', this.get('model.id'));

          subtitle = (0, _scenarioTitle.scenarioTitle)([currentIndexedScenario]);
        }
        this.set('model.subtitle', subtitle);
        this.send('save');
      },
      saveIncrease: function saveIncrease(column) {
        var _this11 = this;

        var model = this.get('model'),
            columnName = column.get('columnName'),
            increaseName = column.get('increase.name'),
            value = column.get('increase.value'),
            unboundName = 'unbound' + columnName.capitalize() + 'Inputs';

        model.set(increaseName, value.toString());
        this.notifyPropertyChange(unboundName);

        Ember.run.later(function () {
          _this11.send('save');
        }, 100);
      },
      updateColumn: function updateColumn(column) {
        this.send('save', column.get('columnName'), column.get('cellValues'));
      },
      updateColumns: function updateColumns(columns) {
        var _this12 = this;

        columns.forEach(function (column) {
          var values = column.get('cellValues');
          values = (0, _stringify.default)(values);
          _this12.get('model').set(column.get('columnName'), values);
        });

        this.send('save');
      },
      saveCurrentVsExpansion: function saveCurrentVsExpansion(selected) {
        this.set('model.currentVsExpansion', selected);
        this.send('save');
      },


      // automatically toggle Expansion vs Total scenario option if new business is selected
      // this will prevent current jobs and other properties from showing in totals
      saveBusinessType: function saveBusinessType() {
        if (this.get('model.isNewBusiness')) {
          this.set('model.currentVsExpansion', 'expansion');
        }
        this.showFirstSectionOnly();
        this.send('save');
      },
      updateDirectCostRev: function updateDirectCostRev(column) {

        if (Array.isArray(column)) {
          column = column[0];
        }

        var scenarioDirectCostRevs = this.get('model.directCostRevenues'),
            costRev = column.get('costRev'),
            directCostRevId = costRev.get('id'),
            values = column.get('cellValues');

        if (!scenarioDirectCostRevs.hasOwnProperty(directCostRevId)) {
          scenarioDirectCostRevs[directCostRevId] = {};
        }

        scenarioDirectCostRevs[directCostRevId].annual = (0, _stringify.default)(values);
        if (column.get('increase')) {
          scenarioDirectCostRevs[directCostRevId].increase = column.get('increase.value').toString();
        }

        // if district was modified before this directCostRev had any values, we need to set a copyOf property in case these are reset
        var district = this.get('sortedDistricts').find(function (district) {
          return district.get('directCostRevenues').indexOf(costRev) > -1;
        }),
            originalDistrictId = district.get('copyOfDistrict'),
            // this means district was modified
        directCostRev = district.get('directCostRevenues').findBy('id', directCostRevId),
            originalDirectId = directCostRev.get('copyOfDirect'); // doesn't exist if created for this modified district

        if (originalDistrictId && originalDirectId && !scenarioDirectCostRevs[directCostRevId].hasOwnProperty('copyOfDirect')) {
          scenarioDirectCostRevs[directCostRevId].copyOfDirect = originalDirectId;
          // we also need to make a new values array to be used as original in case district is reset
          scenarioDirectCostRevs[originalDirectId] = { annual: (0, _stringify.default)(values) };
          if (column.get('increase')) {
            scenarioDirectCostRevs[originalDirectId].increase = column.get('increase.value').toString();
          }
        }

        var originalCostRevId = costRev.get('copyOfDirect');
        // Also update the original direct cost rev in case this was a copy and we eventually reset district defaults
        if (originalCostRevId && scenarioDirectCostRevs.hasOwnProperty(originalCostRevId)) {
          scenarioDirectCostRevs[originalCostRevId].annual = (0, _stringify.default)(values);
          if (column.get('increase')) {
            scenarioDirectCostRevs[originalCostRevId].increase = column.get('increase.value').toString();
          }
        }

        this.notifyPropertyChange('directCostRevenuesChanged');

        this.send('save');
      },
      updateBuildingPermitsFees: function updateBuildingPermitsFees(column) {
        if (Array.isArray(column)) {
          column = column[0];
        }

        var scenarioBuildingPermitsFees = this.get('model.buildingPermitsFees'),
            districtId = column.get('district.id'),
            values = column.get('cellValues');

        if (!scenarioBuildingPermitsFees.hasOwnProperty(districtId)) {
          scenarioBuildingPermitsFees[districtId] = {};
        }

        scenarioBuildingPermitsFees[districtId].annual = (0, _stringify.default)(values);

        // if district was modified before this bpf had any values, we need to set a copyOf property in case these are reset
        var district = this.get('sortedDistricts').findBy('id', districtId),
            originalId = district.get('copyOfDistrict');
        if (originalId && !scenarioBuildingPermitsFees[districtId].hasOwnProperty('copyOfDistrict')) {
          scenarioBuildingPermitsFees[districtId].copyOfDistrict = originalId; // originalId
          // we also need to make a new values array to be used as original in case the district is reset
          scenarioBuildingPermitsFees[originalId] = {};
          scenarioBuildingPermitsFees[originalId].annual = (0, _stringify.default)(values);
        }

        // update original building permits and fees in case this was a copy and we eventually reset district defaults
        var originalDistrictId = scenarioBuildingPermitsFees[districtId].copyOfDistrict;
        if (originalDistrictId && scenarioBuildingPermitsFees.hasOwnProperty(originalDistrictId)) {
          scenarioBuildingPermitsFees[originalDistrictId].annual = (0, _stringify.default)(values);
        }

        this.notifyPropertyChange('buildingPermitsFeesChanged');

        this.send('save');
      },
      toggleMultipliertip: function toggleMultipliertip() {
        this.toggleProperty('multiplierTipActive');
      },
      toggleUsesCustomDirectWorkersMoving: function toggleUsesCustomDirectWorkersMoving() {
        this.toggleProperty('model.usesCustomPercentNewDirectWorkersMoving');
        if (this.get('model.usesCustomPercentNewDirectWorkersMoving')) {
          this.set('model.percentNewDirectWorkersMoving', this.get('primaryDistrict.percentNewDirectWorkersMoving'));
        } else {
          this.set('model.percentNewDirectWorkersMoving', null);
        }
        this.send('save');
      },
      updateUnboundInputs: function updateUnboundInputs() {
        this.notifyPropertyChange('unboundJobsAddedEachYearInputs');
        this.notifyPropertyChange('unboundCapitalInvestmentInputs');
        this.notifyPropertyChange('unboundBuildingPermitsFeesInputs');
        this.notifyPropertyChange('unboundTaxableInventoriesInputs');
        this.notifyPropertyChange('unboundAverageAnnualSalariesInputs');
        this.notifyPropertyChange('unboundTaxablePurchasesInputs');
        this.notifyPropertyChange('unboundTaxableSalesInputs');
        this.notifyPropertyChange('unboundMarketValueFFE');
        this.notifyPropertyChange('unboundDirectCostRevenueInputs');
      },


      // select the first district from each districtGroup
      resetDemoDistricts: function resetDemoDistricts(clearingOrLoading) {

        var districtGroups = this.get('client.districtGroups'),
            districts = this.get('model.districts'),
            selectedDistrict = void 0;

        districtGroups.forEach(function (group) {

          var existing = group.get('districts').find(function (groupDistrict) {
            return districts.find(function (district) {
              return district === groupDistrict;
            });
          });

          if (clearingOrLoading === 'loading') {
            selectedDistrict = group.get('districts').objectAt(1) !== undefined ? group.get('districts').objectAt(1) : group.get('districts.firstObject');
          }

          if (clearingOrLoading === 'clearing') {
            selectedDistrict = group.get('districts.firstObject');
          }

          if (selectedDistrict) {
            if (existing) {
              districts.removeObject(existing);
            }
            districts.pushObject(selectedDistrict);
          }
        });
      },
      loadDemoData: function loadDemoData() {

        this.get('model').rollbackAttributes();
        this.get('model.incentiveSchedules').forEach(function (schedule) {
          schedule.rollbackAttributes();
        });

        this.send('resetDemoDistricts', 'loading');

        this.set('demoDataLoaded', true);

        this.set('model.multiplier', this.get('scenarioMultiplier'));

        // Clear other engines property
        this.notifyPropertyChange('otherEngines');
        this.notifyPropertyChange('sortedDistricts');

        this.send('updateUnboundInputs');
      },
      clearDemoData: function clearDemoData() {

        var zeroedArray = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
            engines = this.get('engines'),
            model = this.get('model');

        this.send('resetDemoDistricts', 'clearing');

        // Clear customCostRevenues
        // sortedDistrictGroups.forEach(function(group) {
        //   districts.push(group.get('districts.content')[0]);
        // });

        // engines.forEach(function(engine, index) {
        //   engine.set('district', districts[index]);
        //   engine.set('customCostRevenues', {revenues: [], costs: []});
        // });

        // Uncheck abatement districts
        Ember.$('.district-check input').each(function () {
          Ember.$(this).prop('checked', false);
        });

        this.set('demoDataLoaded', false);

        model.setProperties({
          subtitle: null,
          description: null,
          employmentMultiplier: '1.0',
          earningsMultiplier: '1.0',
          jobsAddedEachYear: zeroedArray,
          averageAnnualSalaries: zeroedArray,
          averageAnnualSalariesIncrease: '0.02',
          capitalInvestmentLand: [],
          capitalInvestmentBuildings: [],
          capitalInvestmentFurniture: [],
          buildingPermitsFees: zeroedArray,
          taxableInventories: zeroedArray,
          taxableInventoriesAnnualIncrease: '0.02',
          taxablePurchases: zeroedArray,
          taxablePurchasesPercentIncrease: '0.02',
          taxableSales: zeroedArray,
          taxableSalesPercentIncrease: '0.02'
        });

        this.get('model.incentiveSchedules').forEach(function (schedule) {
          schedule.setProperties({
            abatementScheduleLand: zeroedArray,
            abatementScheduleBuildings: zeroedArray,
            abatementScheduleFurniture: zeroedArray,
            abatementScheduleInventories: zeroedArray,
            doesApplyAbatementsLand: true,
            doesApplyAbatementsBuildings: true,
            doesApplyAbatementsFurniture: true,
            doesApplyAbatementsInventories: true,
            incentives: zeroedArray
          });
        });

        engines.forEach(function (engine) {
          engine.setProperties({
            incentiveRateOfReturn: 0
          });
        });

        // Clear unbound inputs
        this.send('updateUnboundInputs');

        model.setProperties({
          capitalInvestmentLand: [],
          capitalInvestmentBuildings: [],
          capitalInvestmentFurniture: []
        });

        model.set('multiplier', null);

        // Clear other engines in ten year table
        this.notifyPropertyChange('otherEngines');
      },
      toggleLockStatus: function toggleLockStatus() {
        var model = this.get('model');
        model.toggleProperty('isAdminUnlocked');
        model.save();
      },


      // For scenarios belonging to clients not using standard district configs
      // This action is invoked to either remove old districts or add new
      updateScenarioDistrictHandler: function updateScenarioDistrictHandler(newDistrict, group) {
        var _this13 = this;

        // returns promise
        this.updateScenarioDistrict(newDistrict, group).then(function () {
          _this13.changeSelectedSummaryEngine(newDistrict);
          _this13.send('updateScenarioMultipliers', newDistrict, group);
          _this13.send('updateUnboundInputs');
          _this13.send('save');
        });
      },

      // scenario multipliers need to be updated if new district uses a different multiplier set
      updateScenarioMultipliers: function updateScenarioMultipliers(newDistrict, group) {
        var multiplierCode = this.get('model.multiplierCode') || '';
        var currentMultiplierCode = this.get('model.currentMultiplierCode') || '';
        var isPrimary = group.get('isPrimary');

        if (!newDistrict || !isPrimary || !multiplierCode || !currentMultiplierCode) {
          return;
        }

        var shortCode = multiplierCode.substr(0, 6);
        var currentShortCode = currentMultiplierCode.substr(0, 6);
        var multipliers = this.get('multipliers');
        var newMultiplierSet = newDistrict.get('multiplierSet');

        // find equivalent multiplier in newly selected district's district area then save scenario?
        var newMultiplier = multipliers.find(function (multiplier) {
          return multiplier.get('code').substr(0, 6) === shortCode && multiplier.get('multiplierSet') === newMultiplierSet.get('id');
        });

        var newCurrentMultiplier = multipliers.find(function (multiplier) {
          return multiplier.get('code').substr(0, 6) === currentShortCode && multiplier.get('multiplierSet') === newMultiplierSet.get('id');
        });

        if (newMultiplier) {
          this.get('model').setProperties({
            multiplier: newMultiplier,
            multiplierCode: newMultiplier.get('code'),
            employmentMultiplier: newMultiplier.get('employment'),
            earningsMultiplier: newMultiplier.get('earnings')
          });
        }

        if (newCurrentMultiplier) {
          this.get('model').setProperties({
            currentMultiplier: newCurrentMultiplier,
            currentMultiplierCode: newCurrentMultiplier.get('code'),
            currentEmploymentMultiplier: newCurrentMultiplier.get('employment'),
            currentEarningsMultiplier: newCurrentMultiplier.get('earnings')
          });
        }
      },
      essentialsUpgradeAction: function essentialsUpgradeAction() {
        this.set('model.essentialsScenario', false);
        this.send('save');
        this.send('goToRoute', 'scenario.index');
      },
      goToRoute: function goToRoute(route) {
        this.transitionToRoute(route);
      }
    }
  });
});